import React, { memo, useEffect } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
  useUpdateNodeInternals
} from '@xyflow/react';
 
const { Title } = Typography;
 
const FirstVisitNode = ({ data }) => {

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals('1_first-visit')
  }, [data.layout])
  
  return (
    <div >
      <Row justify="center">
        <Statistic title={data.label} value={100799299} valueStyle={{fontSize: 16, color: '#001246'}}/>
        <Typography style={{color: '#001246'}}>Max 90d before reg</Typography>
      </Row>
      <Handle type="source" position={data.layout === 'extended' ? Position.Right : Position.Top} id="source" />
    </div>
  );
};
 
export default memo(FirstVisitNode);