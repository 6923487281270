import { noChartDimensions, getArrayOfCubesFromQuery, getArrayOfViewsFromArrayOfCubes, defaultTimeDimensions, getViewFromCube } from '../../config';

export default (oldState, newState) => {
    const { query } = oldState;
    if (newState.query) {
      const oldQuery = query;
      let newQuery = newState.query;
      const { meta } = oldState;
      const lastTouchFields = meta.meta.cubes.find(cube => cube.name === 'RegisteredUsers').dimensions.filter(dim => 'lastTouchFieldFor' in dim.meta).map(dim => dim.meta.lastTouchFieldFor);
      const oldQueryCubes = getArrayOfViewsFromArrayOfCubes(getArrayOfCubesFromQuery(oldQuery,  true), meta.meta.cubes)
                             .filter(cube => Object.keys(defaultTimeDimensions).includes(cube));
      const newQueryCubes = getArrayOfViewsFromArrayOfCubes(getArrayOfCubesFromQuery(newQuery, true), meta.meta.cubes)
                             .filter(cube => Object.keys(defaultTimeDimensions).includes(cube));
      const unionOldAndNewQueryCubes = [...new Set([...oldQueryCubes, ...newQueryCubes].flat())];
      // by default we get a table
      newState = {
          ...newState,
          chartType: 'table'
      };
      // adding oder by on measure selected
      if ('measures' in newQuery && newQuery.measures.length > 0){
        let measure = newQuery.measures[0];
        if (!('order' in newQuery)){
          newQuery = {...newQuery, order: { [measure]: 'desc' } }
        } else {
          if (Object.keys(newQuery.order)[0] !== measure){
            newQuery = {...newQuery, order: { [measure]: 'desc' } }
          }
        }
        newState = {
            ...newState,
            query: newQuery,
        };
      }

      // Update of chartType
      if ((newQuery.timeDimensions || []).length >= 1
        && newQuery.timeDimensions.map(td => td?.granularity).filter(td => td).length == 1
        // && newQuery.timeDimensions[0].granularity
      ){
        if ((newQuery.dimensions || []).length === 0 && (newQuery.measures || []).length === 1) {
            newState = {
                ...newState,
                chartType: 'area'
            };
        }
        if ((newQuery.dimensions || []).length === 0 && (newQuery.measures || []).length > 1) {
            newState = {
                ...newState,
                chartType: (newQuery.measures || []).length === 2 && newQuery.measures[0].split('.')[0] !== newQuery.measures[1].split('.')[0] ? 'barLine': 'line'
            };
        }
        if ((newQuery.dimensions || []).length === 1 && (newQuery.measures || []).length === 1 && !noChartDimensions.includes(newQuery.dimensions[0])) {
            newState = {
                ...newState,
                chartType: 'line'
            };
        }
        if ((newQuery.dimensions || []).length > 1) {
            newState = {
                ...newState,
                chartType: 'table'
            };
        }
      } else {
        if ((newQuery.dimensions || []).length === 0 && (newQuery.measures || []).length === 1) {
            newState = {
                ...newState,
                chartType: 'number'
            };
        }
        if 
            (
              (
                (
                  (newQuery.dimensions || []).length === 1 && !noChartDimensions.includes(newQuery.dimensions[0])
                ) || (
                  (newQuery.dimensions || []).length === 2 && !noChartDimensions.includes(newQuery.dimensions[0]) && !noChartDimensions.includes(newQuery.dimensions[1])
              ) && (newQuery.measures || []).length === 1
              )
            || (((newQuery.measures || []).length === 1 || (newQuery.measures || []).length === 2) && (newQuery.dimensions || []).length === 1 && !noChartDimensions.includes(newQuery.dimensions[0]))
            ) {
            newState = {
                ...newState,
                chartType: (newQuery.measures || []).length === 2 && newQuery.measures[0].split('.')[0] !== newQuery.measures[1].split('.')[0] ? 'barLine': 'bar'
            };
        }
        if (((newQuery.dimensions || []).length === 2 && (newQuery.measures || []).length > 1) || ((newQuery.measures || []).length <= 1 && (newQuery.dimensions || []).length > 2)) {
            newState = {
                ...newState,
                chartType: 'table'
            };
        }
      }
      
      if ((newQuery.measures || []).length === 0){ // 0 measures = we want table
        newState = {
          ...newState,
          chartType: 'table'
        };
      }
      // End update chartype


      // Update of timeDimension based on measure selected
      unionOldAndNewQueryCubes.forEach(queryCube => {
         // do nothing!
        // case exist in old but not new => removed!
        if ('timeDimensions' in oldQuery && oldQueryCubes.includes(queryCube) && !newQueryCubes.includes(queryCube) && Object.keys(newQuery).length > 1) {
          newQuery = {
            ...newQuery,
            timeDimensions: oldQuery.timeDimensions.filter(td => queryCube !== td.dimension.split('.')[0]),
          }
        }
        let newTimeDimension = defaultTimeDimensions[queryCube]['dateRange'] === 'All time' ?
          {
            dimension: defaultTimeDimensions[queryCube]['dimension'], 
          } :
          {
            dimension: defaultTimeDimensions[queryCube]['dimension'], 
            dateRange: defaultTimeDimensions[queryCube]['dateRange'],
          }
        // case of new subscribers
        if(
          'measures' in newQuery && newQuery.measures.includes('Subscriptions.new_subscribers') 
          && ['Subscriptions', 'Google Analytics 4', 'Campaigns'].includes(queryCube)
          && (
            (JSON.stringify((oldQuery.measures || [])) !== JSON.stringify((newQuery.measures || []))) ||
            (JSON.stringify((oldQuery.dimensions || [])) !== JSON.stringify((newQuery.dimensions || []))) ||
            (JSON.stringify((oldQuery.filters || []).map(fil => fil.member)) !== JSON.stringify((newQuery.filters || []).map(fil => fil.member))) ||
            (JSON.stringify((oldQuery.segments || [])) !== JSON.stringify((newQuery.segments || [])))
          )
        ){
          let action = 'ADD';
          const justAddedNewSubsMetric = !('measures' in oldQuery && oldQuery.measures.includes('Subscriptions.new_subscribers'));
          const prevhasGA = getArrayOfCubesFromQuery(newQuery).includes('Events')
          const haveGAorCommentmetric = (newQuery.measures || []).filter(measure => ['Events', 'CommentsGA4', 'CampaignsUnion'].includes(measure.split('.')[0])).length > 0;
          const newQueryDimensions = newQuery.dimensions?.filter(dim => ['Events', 'CommentsGA4', 'CampaignsUnion'].includes(dim.split('.')[0])).map(dim => dim.split('.')[1]) || [];
          const newQueryFilters = newQuery.filters?.filter(dim => ['Events', 'CommentsGA4', 'CampaignsUnion'].includes(dim.member.split('.')[0])).map(fil => fil.member.split('.')[1]) || [];
          const concatNewDimensionsFilters = newQueryDimensions.concat(newQueryFilters);
          const GAdimsAreAllLastTouchFields = concatNewDimensionsFilters.every(item => lastTouchFields.includes(item));
          // case there is no GA
          // ADD: we add Subscription Active Date
          // case there are GA non last touch fields: check on dim/filter + any metric
          // NO_ADD: we don't add Subscription Active Date
          // case there are GA last touch fields: no GA metric + only dim/filter as last touch fields
          // SWAP 1: we swap Traffic Date with Subscription Active Date
          // case we have already new subs and we add non last touch GA
          // SWAP 2: we swap Subscription Active Date with Traffic Date
          action = (justAddedNewSubsMetric && !prevhasGA) ? 'ADD' 
                    : prevhasGA && !haveGAorCommentmetric && GAdimsAreAllLastTouchFields ? 'SWAP 1' 
                    : !justAddedNewSubsMetric && (haveGAorCommentmetric || !GAdimsAreAllLastTouchFields) ? 'SWAP 2'
                    : 'NO_ADD'
          if (action === 'ADD' && !('timeDimensions' in newQuery && newQuery.timeDimensions.map(td => td.dimension).includes(newTimeDimension.dimension))){
            newQuery = {
              ...newQuery,
              timeDimensions: 'timeDimensions' in oldQuery ? oldQuery.timeDimensions.concat(newTimeDimension) : [newTimeDimension],
            }
          } else if (action === 'SWAP 1'){
            // Change Traffic TD dimension to be Subscription Date TD dimension
            newQuery = {
              ...newQuery,
              timeDimensions: oldQuery.timeDimensions.map(td => {
                 if(td.dimension === 'Events.date'){
                  td.dimension = newTimeDimension.dimension;
                  td.dateRange = td.dateRange ? td.dateRange : newTimeDimension.dateRange;
                 }
                 return td;
              }),
            }
          } else if (action === 'SWAP 2'){
            // Change Subscriptions TD dimension to be Traffic Date TD dimension
            newQuery = {
              ...newQuery,
              timeDimensions: oldQuery.timeDimensions.map(td => {
                 if(td.dimension.split('.')[0] === 'Subscriptions'){
                  td.dimension = newTimeDimension.dimension;
                  td.dateRange = td.dateRange ? td.dateRange : newTimeDimension.dateRange;
                 }
                 return td;
              }),
            }
          }
          
        } else if (
            // case exist in new but not old => added!
            !oldQueryCubes.includes(queryCube) && newQueryCubes.includes(queryCube) 
            && !('timeDimensions' in newQuery && newQuery.timeDimensions.map(td => td.dimension).includes(defaultTimeDimensions[queryCube]['dimension']))
          ){
          newQuery = {
            ...newQuery,
            timeDimensions: 'timeDimensions' in oldQuery ? oldQuery.timeDimensions.concat(newTimeDimension) : [newTimeDimension],
          }
        }
      })

      if (newQueryCubes.includes('Batch')){
        
        // case AB test not in old but in new
        if (!oldQuery.dimensions?.includes('BatchCampaigns.variant') && newQuery.dimensions?.includes('BatchCampaigns.variant')){
          if(oldQuery.timeDimensions?.map(td => td.dimension).includes('BatchCampaigns.push_time')){
            newQuery = {
              ...newQuery,
              timeDimensions: oldQuery.timeDimensions.filter(td => td.dimension === 'BatchEvents.event_date'),
            }
          }  
          newQuery = {
            ...newQuery,
            timeDimensions: newQuery.timeDimensions.map(td => {
                if(td.dimension.split('.')[0] === 'BatchEvents'){
                  td.dimension = 'BatchCampaigns.push_time';
                }
                return td;
            }),
          }  
        }
        // case AB test in old but no longer in new
        if (oldQuery.dimensions?.includes('BatchCampaigns.variant') && !newQuery.dimensions?.includes('BatchCampaigns.variant')){
          newQuery = {
            ...newQuery,
            timeDimensions: oldQuery.timeDimensions.map(td => {
               if(td.dimension.split('.')[0] === 'BatchCampaigns'){
                td.dimension = 'BatchEvents.event_date';
               }
               return td;
            }),
          }
        }

      }
      // Addition of time dimension => Need to apply the default dateRange
      if ((oldQuery.timeDimensions || []).length < (newQuery.timeDimensions || []).length){
        const newTimeDimension = newQuery.timeDimensions.filter(td => !(oldQuery.timeDimensions || []).map(td => td.dimension).includes(td.dimension))[0].dimension;
        newQuery.timeDimensions  = newQuery.timeDimensions.map(td => {
          if(td.dimension === newTimeDimension  && defaultTimeDimensions[getViewFromCube(meta.meta.cubes, newTimeDimension.split('.')[0])]['dateRange'] === 'All time'){
            return {
              dimension: newTimeDimension,
            }
          }
          if(td.dimension === newTimeDimension && defaultTimeDimensions[getViewFromCube(meta.meta.cubes, newTimeDimension.split('.')[0])]['dateRange'] !== 'All time'){
            return {
              dimension: newTimeDimension,
              dateRange: defaultTimeDimensions[getViewFromCube(meta.meta.cubes, newTimeDimension.split('.')[0])]['dateRange'],
            }
          }
          return td;
        })
      }
      // Removal of time dimension => Need to remove all query members of that cube
      if ((oldQuery.timeDimensions || []).length > (newQuery.timeDimensions || []).length){
        const removedTimeDimension = oldQuery.timeDimensions.filter(td => !(newQuery.timeDimensions || []).map(td => td.dimension).includes(td.dimension))[0]?.dimension;
        const removedCube = getViewFromCube(meta.meta.cubes, removedTimeDimension?.split('.')[0]);
        if('dimensions' in newQuery && newQuery.dimensions.map(dim => getViewFromCube(meta.meta.cubes, dim.split('.')[0])).includes(removedCube)){
          newQuery.dimensions = newQuery.dimensions.filter(dim => getViewFromCube(meta.meta.cubes, dim.split('.')[0]) !== removedCube);
        }
        if('measures' in newQuery && newQuery.measures.map(mea => getViewFromCube(meta.meta.cubes, mea.split('.')[0])).includes(removedCube)){
          newQuery.measures = newQuery.measures.filter(mea => getViewFromCube(meta.meta.cubes, mea.split('.')[0]) !== removedCube);
        }
        if('segments' in newQuery && newQuery.segments.map(seg => getViewFromCube(meta.meta.cubes, seg.split('.')[0])).includes(removedCube)){
          newQuery.segments = newQuery.segments.filter(seg => getViewFromCube(meta.meta.cubes, seg.split('.')[0]) !== removedCube);
        }
        if('filters' in newQuery && newQuery.filters.map(fil => getViewFromCube(meta.meta.cubes, fil.member.split('.')[0])).includes(removedCube)){
          newQuery.filters = newQuery.filters.filter(fil => getViewFromCube(meta.meta.cubes, fil.member.split('.')[0]) !== removedCube);
        }
      }

      // Reorder time dimensions when more than 1
      if ((newQuery.timeDimensions || []).length > 1){
        let sortedTimeDimensions = newQuery.timeDimensions.sort(
          (td1, td2) => (defaultTimeDimensions[getViewFromCube(meta.meta.cubes, td1.dimension.split('.')[0])]['priority'] < defaultTimeDimensions[getViewFromCube(meta.meta.cubes, td2.dimension.split('.')[0])]['priority']) ? -1 
            : (defaultTimeDimensions[getViewFromCube(meta.meta.cubes, td1.dimension.split('.')[0])]['priority'] > defaultTimeDimensions[getViewFromCube(meta.meta.cubes, td2.dimension.split('.')[0])]['priority']) ? 1 : 0
        );
        newQuery.timeDimensions = sortedTimeDimensions;
      }

      // Reset
      // if (
      //   (
      //     (oldQuery.dimensions || []).length > 0
      //     || (oldQuery.measures || []).length > 0
      //   )
      //   && (newQuery.dimensions || []).length === 0
      //   && (newQuery.measures || []).length === 0
      // ) {
      //   newQuery = {
      //     ...newQuery,
      //     timeDimensions: [],
      //     filters: []
      //   };
      //   return {
      //     ...newState,
      //     query: newQuery,
      //     sessionGranularity: null
      //   };
      // }
      return {
            ...newState,
            query: newQuery,
        };
    }

    return newState;
  }