import {SCMP_PALE_COLORS_SERIES, SCMP_COLORS_SERIES} from '../../ChartContainer/chartConfigs';


export const getRegistrationEntryPointChartData = (dataChart, labels) => {
  return {
    labels: labels,
    datasets: [
      {
        label: 'Users',
        data: dataChart,
        borderColor: SCMP_COLORS_SERIES[0],
        backgroundColor: SCMP_PALE_COLORS_SERIES[0],
        yValues: ['Users'],
        fill: false,
      },
    ],
  }
}


  export const getPlatformChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES,
          backgroundColor: SCMP_PALE_COLORS_SERIES,
          yValues: ['Users'],
          fill: false,
        },
      ],
    }
  }

  export const getNewRegistrationsChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'New Registrations',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES[1],
          backgroundColor: SCMP_PALE_COLORS_SERIES[1],
          yValues: ['New Registrations'],
          fill: true,
          pointStyle: false
        },
      ],
    }
  }

  export const getRegionChartData = (dataChart, labels) => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: dataChart,
          borderColor: SCMP_COLORS_SERIES,
          backgroundColor: SCMP_PALE_COLORS_SERIES,
          yValues: ['Users'],
          fill: false,
        },
      ],
    }
  }