
export const getDrupalQuery = (articleID, limit) => ({
    dimensions: [
      "Drupal.article_id", "Drupal.title", "Drupal.publication_date", "Drupal.authors_name",
      "Drupal.sub_sections", "Drupal.desk", "Drupal.content_lock", "Drupal.url", "Drupal.keywords",
      "Drupal.topics", "Drupal.word_count", "DrupalArticleImages.image_url", "Drupal.user_needs",
    ], 
    filters: [{member: "Drupal.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleGAQuery = (articleID, limit) => ({
    measures: ["ArticleGA.loy_reg_users"],
    filters: [{member: "ArticleGA.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticleUnionQuery = (articleID, limit) => ({
    measures: ["ArticleAppleGAUnion.users"],
    filters: [{member: "ArticleAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getRegionQuery = (articleID, limit) => ({
    dimensions: ["ArticleCountryAppleGAUnion.country"],
    measures: ["ArticleCountryAppleGAUnion.users"],
    filters: [{member: "ArticleCountryAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getArticlePlatformUnionQuery = (articleID, limit) => ({
    dimensions: ["ArticlePlatformAppleGAUnion.platform"],
    measures: ["ArticlePlatformAppleGAUnion.users"],
    filters: [{member: "ArticlePlatformAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });
  
export const getNewRegistrationsQuery = (articleID, limit) => ({
    dimensions: ["ArticleNewRegistrationsAppleGAUnion.traffic_source"],
    measures: ["ArticleNewRegistrationsAppleGAUnion.users"],
    filters: [{member: "ArticleNewRegistrationsAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });

  
export const getRegistrationEntryPointQuery = (articleID, limit, dateRange) => ({
    measures: ["ArticleDayAppleGAUnion.pageviews"],
    timeDimensions: [{
      dimension: "ArticleDayAppleGAUnion.date",
      dateRange: dateRange,
      granularity: "day",
    }],
    filters: [{member: "ArticleDayAppleGAUnion.article_id", operator: "equals", values: [articleID]}],
    limit: limit,
  });


export const getExploreRegionQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.country"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExplorePlatformQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.platform"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreNewRegistrationsQuery = (articleID, dateRange) => ({
    dimensions: ["GA4AppleUnion.traffic_source"],
    measures: ["GA4AppleUnion.users"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreRegistrationEntryPointQuery = (articleID, dateRange) => ({
    measures: ["GA4AppleUnion.pageviews"],
    timeDimensions: [{
        dimension: "GA4AppleUnion.date",
        dateRange: dateRange,
        granularity: "day",
    }],
    filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});


export const getExploreConversionRateQuery = (articleID, dateRange) => ({
  measures: ["GA4AppleUnion.pageviews"],
  timeDimensions: [{
      dimension: "GA4AppleUnion.date",
      dateRange: dateRange,
      granularity: "day",
  }],
  filters: [{member: "DrupalGA4Apple.article_id", operator: "equals", values: [articleID]}],
});

