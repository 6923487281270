import { useHistory } from 'react-router-dom';

import { QueryBuilderContainer } from '../components/Explore/QueryBuilderContainer';
import { defaultAudienceQuery } from '../config';
import { Alert} from '../atoms';
import { Row, Col, Typography } from 'antd';
import { useSecurityContext } from '../hooks';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';

const { Title } = Typography;

const StyledTitle = styled(Title)`
&.ant-typography {
  color: var(--dark-02-color);
  margin-bottom: 4px;
}
`;

export function AudienceBuilderPage() {
  
  const { push } = useHistory();
  const { currentToken } = useSecurityContext();

  function setQueryParam({ query }) {
    if (!('dimensions' in query) || query.dimensions.length === 0) {
      query = defaultAudienceQuery;
    } 
    push({ search: `?query=${encodeURIComponent(JSON.stringify(query))}` });
  }

  return (
    ['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data', 'Product & Technology', 'Advertising & Marketing Solutions'].includes(jwtDecode(currentToken).user_department) ? <QueryBuilderContainer
      schemaVersion={0}
      onVizStateChanged={setQueryParam}
      onTabChange={setQueryParam}
      mode="audience"
    /> : <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', marginTop: 36}}>
                        
                        <Row align='middle' justify="space-between" style={{width: '100%', marginBottom: 980}}>
                            <Col span={18} offset={3} type="flex">
                                <StyledTitle style={{marginBottom: "16px"}}>Audience Builder</StyledTitle>
                                    
                                    <Alert 
                                        message="Access Denied"
                                        description="Contact jonathan.barone@scmp.com on slack in #exodus channel or in DM if you wish to get access."
                                        type="error" 
                                        showIcon 
                                    />
                                  
                            </Col>
                        </Row>
                    </div>
  );
}