import { Container, Card } from '../atoms';
import { List, Avatar } from 'antd';
import { NoCloseTabs } from '../atoms/NoCloseTabs';
import styled from 'styled-components';


const Wrapper = styled.div`
  background-color: var(--layout-body-background);
  padding-bottom: 16px;
`;

const Meta = styled(List.Item.Meta)`
&& {
    .ant-list-item-meta-title {
      margin-bottom: 0px;
    }
  }
`


const { TabPane } = NoCloseTabs;

const dailyData = [
  {
    title: 'Daily Article Report',
    url: 'https://lookerstudio.google.com/s/pHq39mUbLUw'
  },
  {
    title: 'Rosetta, subscriptions per desk',
    url: 'https://lookerstudio.google.com/reporting/2738157f-51f2-4129-8b9f-970c7d2687da/page/p_wa1vygredd?utm_source=google-datastudio&utm_medium=email&utm_campaign=scheduled-report'
  },
  {
    title: 'City Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/vVEcX0CFWeU'
  },
  {
    title: 'China Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/omZVCjXC1T4'
  },
  {
    title: 'Economy Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/tRNj2SpGpnc'
  },
  {
    title: 'Business Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/uIJjyMyNGOQ'
  },
  {
    title: 'Tech Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/qaIFyej-wY8'
  },
  {
    title: 'People and Culture Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/vbfcDK6M9hM'
  },
  {
    title: 'This Week In Asia Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/gJT723b1eBU'
  },
  {
    title: 'Asia Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/qDpcPZ6jhkw'
  },
  {
    title: 'Lifestyle Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/jqU448rU5wc'
  },
  {
    title: 'Sport Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/tZtrDo4kXhU'
  },
  {
    title: 'Opinion Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/p9xIBW7rEpA'
  },
  {
    title: 'Young Post Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/vPVcD-6T3WE'
  },
  {
    title: 'World Desk Daily Report',
    url: 'https://lookerstudio.google.com/s/sYCg46rL4R0'
  },
  {
    title: 'Postmag Daily Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/87a08915-b6ea-4244-977e-d197a8c8dbb7/page/avylC'
  },
  {
    title: 'Style Daily Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/07c41932-f4a9-4ae5-b942-3aa86858e41a/page/avylC'
  },
  {
    title: 'Posties Daily Report',
    url: 'https://lookerstudio.google.com/reporting/1c1e544b-0a00-4096-bafa-f56a28a2250e'
  }
];


const weeklyData = [
  {
    title: 'Weekly Article Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/bf6b466b-4819-42fc-af75-342bc108cf98/page/avylC'
  },
  {
    title: 'City Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/c4b818d8-53a1-42dc-afcd-733a3f8ff009/page/avylC'
  },
  {
    title: 'China Desk Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/6336893a-9902-4355-982a-4aa619a7f3c4/page/avylC'
  },
  {
    title: 'Economy Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/e1827a9b-289b-4b6b-8759-c0cb0113845f/page/avylC'
  },
  {
    title: 'Business Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/0ece5bb0-5fb6-484c-87e5-53537d37ba23/page/avylC'
  },
  {
    title: 'Tech Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/418a430a-4f40-49c1-bbde-6fe3691e1217/page/avylC'
  },
  {
    title: 'People and Culture Desk Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/786c43b2-53b3-4e70-be11-724f1dc02926/page/avylC'
  },
  {
    title: 'This Week In Asia Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/2725f316-12e0-4b91-8097-2997202bd8bd/page/avylC'
  },
  {
    title: 'Asia Desk Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/ecdd00cb-2b38-4710-8084-7a8c6243800b/page/avylC'
  },
  {
    title: 'Lifestyle Desk Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/22ea4797-d8a0-4445-a06f-198fb6b2abe1/page/avylC'
  },
  {
    title: 'Sport Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/32ba4c54-3cd6-4ae0-804d-3d78c106fedd/page/avylC'
  },
  {
    title: 'Opinion Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/0aecfe00-eb52-4acd-98fc-5fa55fe90a62/page/avylC'
  },
  {
    title: 'Young Post Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/32bf3b84-1f21-4e5f-bcc9-383f0794b80f/page/avylC'
  },
  {
    title: 'World Desk Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/d98ef727-39f6-474a-a611-495fa42264c0/page/avylC'
  },
  {
    title: 'Postmag Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/87a08915-b6ea-4244-977e-d197a8c8dbb7/page/avylC'
  },
  {
    title: 'Style Weekly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/9c8e53b5-28e5-4b1c-b55a-e31c21ea192a/page/avylC'
  },
  {
    title: 'Posties Weekly Report',
    url: 'https://lookerstudio.google.com/reporting/a9b88e91-738a-4942-b328-674eb05cd649'
  }
];


const monthlyData = [
  {
    title: 'Monthly Article Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/5007af99-dbca-4ab5-8258-ef8d02d5ad42/page/avylC'
  },
  {
    title: 'City Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/030d73bc-ca73-423a-afd4-aea736974e6a/page/avylC'
  },
  {
    title: 'China Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/b527960b-0c9f-4d40-bdf3-93007b4a3705/page/avylC'
  },
  {
    title: 'Economy Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/12581d46-76c6-4659-a646-b36f2874a920/page/avylC'
  },
  {
    title: 'Business Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/118f022e-ac68-4eef-bf00-905548d41820/page/avylC'
  },
  {
    title: 'Tech Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/585b135a-b681-4b13-a39b-cae0c5af9aa9/page/avylC'
  },
  {
    title: 'People and Culture Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/f27b0eda-ab00-4e85-9ca7-8dfeaf4857ea/page/avylC'
  },
  {
    title: 'This Week In Asia Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/d3f833e8-fd82-48c2-89d4-fc69e1559be5/page/avylC'
  },
  {
    title: 'Asia Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/e0a803da-124f-456d-9abb-d34255fcfac8/page/avylC'
  },
  {
    title: 'Lifestyle Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/d9202f6a-39ac-4099-b712-683dc8c7e4be/page/avylC'
  },
  {
    title: 'Sport Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/98b9b591-dddd-4e6d-9aff-71864c207da3/page/avylC'
  },
  {
    title: 'Opinion Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/222bb01f-8368-41fe-9a54-64e0cec65a26/page/avylC'
  },
  {
    title: 'Young Post Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/f39eacd2-2d0a-485b-9681-30515301d63c/page/avylC'
  },
  {
    title: 'World Desk Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/8333eb71-9b39-4b13-bb5d-e98c1b3a9522/page/avylC'
  },
  {
    title: 'Postmag Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/1f270ce5-a626-438e-8be5-f26da05cd046/page/avylC'
  },
  {
    title: 'Style Monthly Report',
    url: 'https://lookerstudio.google.com/u/0/reporting/b16fbd38-9d97-48c4-a046-6b93f9addec6/page/avylC'
  },
  {
    title: 'Posties Monthly Report',
    url: 'https://lookerstudio.google.com/reporting/134beb89-fcae-4f26-80f5-b4bc51eee1f8'
  }
];


export function OtherDashboardsPage() {

  return (
    <Container bordered={false}> 
      <NoCloseTabs 
        tabBarExtraContent={{
          right: null,
        }}
        tabBarStyle={{borderColor: 'transparent'}}
        hideAdd={true}
        type="editable-card"
      >
        <TabPane tab={<div style={{padding: "4px 16px"}}>Daily</div>} key="daily">
          <Wrapper>
            <Card bordered={false} style={{ borderRadius: '0 8px 8px 8px'}}> 
              <List
                itemLayout="horizontal"
                dataSource={dailyData}
                renderItem={(item) => (
                  <List.Item>
                    <Meta
                      avatar={<Avatar size="small" src={`${process.env.PUBLIC_URL}/looker_studio_logo.png`} />}
                      title={<a href={item.url} noopener noreferrer target="_blank">{item.title}</a>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Wrapper>
        </TabPane>
        <TabPane tab={<div style={{padding: "4px 16px"}}>Weekly</div>} key="weekly">
          <Wrapper>
          <Card bordered={false} style={{ borderRadius: '0 8px 8px 8px'}}> 
              <List
                itemLayout="horizontal"
                dataSource={weeklyData}
                renderItem={(item) => (
                  <List.Item>
                    <Meta
                      avatar={<Avatar size="small" src={`${process.env.PUBLIC_URL}/looker_studio_logo.png`} />}
                      title={<a href={item.url} noopener noreferrer target="_blank">{item.title}</a>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Wrapper>
        </TabPane>
        <TabPane tab={<div style={{padding: "4px 16px"}}>Monthly</div>} key="monthly">
          <Wrapper>
          <Card bordered={false} style={{ borderRadius: '0 8px 8px 8px'}}> 
              <List
                itemLayout="horizontal"
                dataSource={monthlyData}
                renderItem={(item) => (
                  <List.Item>
                    <Meta
                      avatar={<Avatar size="small" src={`${process.env.PUBLIC_URL}/looker_studio_logo.png`} />}
                      title={<a href={item.url} noopener noreferrer target="_blank">{item.title}</a>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Wrapper>
        </TabPane>
      </NoCloseTabs>
   </Container>
  );
}