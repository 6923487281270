import { useCubeMeta, useDryRun } from '@cubejs-client/react';
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSecurityContext } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { getArrayOfCubesFromQuery, getAuthorisedDeptFromCube } from '../../config';

export const Context = createContext({});

export function QueryContext({
  cubejsApi,
  children,
  metaExtended,
  department,
  ...props
}) {
  const [error, setError] = useState(null);
  const [query, setQuery] = useState({});
  const [transformedQuery, setTransformedQuery] = useState(null);
  const [GASchema, setGASchema] = useState('GA4');
  const [mode, setMode] = useState('query');
  const [renderProps, setRenderProps] = useState({});
  const [chartOption, setChartOption] = useState('table');
  const [chartToDisplay, setChartToDisplay] = useState('line');
  const [schemaChangedByUser, setSchemaChangedByUser] = useState(0);

  const [memberTypeCubeMap, setMemberTypeCubeMap] = useState({
    measures: [],
    dimensions: [],
    segments: [],
    timeDimensions: [],
  });

  const metaResult = useCubeMeta({
    skip: false,
    cubejsApi,
  });
  const dryRunResult = useDryRun(query, {
    skip: true,
    cubejsApi,
  });
  let { isLoggedIn, decodeCheckExpiredToken, currentToken } = useSecurityContext();
  const { push } = useHistory();

  useEffect(() => {
    const { isLoading, error, response } = metaResult;
    if (!isLoading) {
      if (response) {
        setMemberTypeCubeMap(response.membersGroupedByCube());
      } else if (error) {
        setError(error);
      }
    }
  }, [metaResult.isLoading]);


  // check query can be ran by user
  useEffect(() => {
    if (query && getArrayOfCubesFromQuery(query).length > 0) {
      let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      }
      const jsonQuery = JSON.stringify(query)
      fetch(`${process.env.REACT_APP_CUBEJS_API_BASE_URL}/v1/sql?query=${jsonQuery}`, requestOptions)
        .then(response => {
          if (!response.ok) {
            setError(response.statusText);
          }
        })
      // Distinguish between UA and UA + Apple
      // or GA4 and GA4 + Apple
      const authorisedReportingDepartments = getAuthorisedDeptFromCube(metaExtended, 'ManagementEvents');
      if (
        (getArrayOfCubesFromQuery(query).includes('ManagementEvents') || getArrayOfCubesFromQuery(query).includes('ManagementDrupal'))
        && authorisedReportingDepartments && authorisedReportingDepartments.length > 0 && authorisedReportingDepartments.includes(department)
      ){
        setGASchema('GA4')
        setMode('reporting')
      } else if (
        (getArrayOfCubesFromQuery(query).includes('ManagementGA') || getArrayOfCubesFromQuery(query).includes('ManagementDrupal'))
        && authorisedReportingDepartments && authorisedReportingDepartments.length > 0 && authorisedReportingDepartments.includes(department)
      ){
        setGASchema('UA')
        setMode('reporting')
      }
      else if (
        (getArrayOfCubesFromQuery(query).includes('GA') || getArrayOfCubesFromQuery(query).includes('Drupal')
        || getArrayOfCubesFromQuery(query).includes('UAAppleUnion') || getArrayOfCubesFromQuery(query).includes('DrupalUAApple'))
      ){
        setGASchema('UA')
      } else if (
        getArrayOfCubesFromQuery(query).includes('CubeQueries')
      ){
        setGASchema('cube')
      } else {
        setGASchema('GA4')
      }
    } 
  }, [query])

  useEffect(() => {
    const { isLoading, response } = dryRunResult;
    if (isLoggedIn){
        console.log('checking token expiration...')
        decodeCheckExpiredToken();
    } else {
        push('/login');
    }
    if (!isLoading) {
      if (response) {
        setTransformedQuery(response.transformedQueries[0]);
      } 
    }
  }, [dryRunResult.isLoading, isLoggedIn]);

  return (
    <Context.Provider
      value={{
        isLoading: metaResult.isLoading || dryRunResult.isLoading,
        query,
        setQuery,
        transformedQuery,
        setTransformedQuery,
        memberTypeCubeMap,
        error,
        GASchema,
        setGASchema,
        mode,
        setMode,
        renderProps,
        setRenderProps,
        chartOption,
        setChartOption,
        chartToDisplay,
        setChartToDisplay,
        schemaChangedByUser,
        setSchemaChangedByUser,
      }}
    >
      {children}

    </Context.Provider>
  );
}

export function useQueryContext() {
  return useContext(Context);
}