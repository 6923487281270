import React, { useState } from 'react';
import { addIncrementalKey, avg, makePercentage } from '../../utils';
import { Spin, Row, Col, Statistic, Table, Typography, Input, Empty, Image, Tooltip as AntdTooltip, Steps } from 'antd';
import { Tabs } from '../../atoms';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import moment from 'moment';
import { CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Chart, TimeScale, Tooltip, Legend, Filler, BarElement } from "chart.js";
import { COLORS_SERIES, PALE_COLORS_SERIES, DEFAULT_HEIGHT, commonOptions, tickFormatting } from './chartConfigs';
import Recommendations from '../Recommendations/Recommendations';
import { recommendedQueriesCompanyKPIs, recommendedQueriesAcquisition, recommendedQueriesContentPerformance, recommendedQueriesNewsletters, recommendedQueriesOnsiteEngagement, 
  recommendedQueriesPushAlerts, recommendedQueriesSubscriptions, recommendedQueriesRecirculation } from '../Recommendations/queries';

const { Title } = Typography;
const { Search } = Input;

Chart.register(CategoryScale, ArcElement, LinearScale, PointElement, LineElement, TimeScale, Tooltip, Legend, Filler, BarElement);

const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];
      if (typeof onDrilldownRequested === 'function') {
        onDrilldownRequested(
          {
            xValues,
            yValues,
          },
          pivotConfig
        );
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

const LineChartRenderer = ({
  dataChart,
  labels,
  colorSet,
  onDrilldownRequested
}) => {
  
  const datasets = useDeepCompareMemo(
    () =>
    dataChart.map((s, index) => ({
      label: s.shortTitle.split(',')[0],
      data: s.series.map((r) => r.value),
      yValues: [s.key],
      borderColor: colorSet[index % colorSet.length],
      backgroundColor: colorSet[index % colorSet.length],
    })),
    [dataChart]
  );

  const data = {
    labels: labels,
    datasets,
  };

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
  });

  return (
    <Line
      type="line"
      data={data}
      height={DEFAULT_HEIGHT}
      options={commonOptions}
      // onClick={getElementAtEvent}
    />
  );
};

const BarChartRenderer = ({ dataChart, labels, colorSet, onDrilldownRequested }) => {
  
  const datasets = useDeepCompareMemo(
    () => {
      return dataChart.map((s, index) => ({
        label: s.shortTitle.split(',')[0],
        data: s.series.map((r) => r.value),
        yValues: [s.key],
        backgroundColor: colorSet[index % colorSet.length],
        borderColor: colorSet[index % colorSet.length],
        fill: false,
        order:2
      }))},
    [dataChart]
  );
  
  const data = {
    labels: labels,
    datasets: dataChart.length === 1 ? datasets.concat({
      label: 'Average',
      data: Array(dataChart.map(s => s.series.map(r => r.value))[0].length).fill(avg(dataChart.map(s => s.series.map(r => r.value))[0])),
      borderColor: '#c04b86a6',
      backgroundColor: '#c04b86a6',
      borderDash: [3],
      pointRadius: 0,
      order:1,
      type: 'line'
    }) : datasets,
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
  });
  let options = {
    ...commonOptions,
    scales: {
      y: {
        ...commonOptions.scales.y,
        stacked: true,
        type: 'linear',
        position: 'left',
      },
      x: {
        ...commonOptions.scales.x,
        stacked: true,
      },
    },
  };
  return (
    <Bar
      type="bar"
      data={data}
      height={DEFAULT_HEIGHT}
      options={options}
      // onClick={getElementAtEvent}
    />
  );
};

const BarLineChartRenderer = ({ dataChart, labels, colorSet, onDrilldownRequested }) => {
  
  let compare = (a, b) => {
    if (['Events', 'GA4AppleUnion'].includes(a.key.split('.')[0])) {
        return -1;
    }
    if (['Events', 'GA4AppleUnion'].includes(b.key.split('.')[0])) {
        return 1;
    }
    return 0;
  };

  const datasets = useDeepCompareMemo(
    () => {
      return dataChart.sort(compare).map((s, index) => ({
        label: s.shortTitle.split(',')[0],
        data: s.series.map((r) => r.value),
        yValues: [s.key],
        backgroundColor: colorSet[index % colorSet.length],
        borderColor: colorSet[index % colorSet.length],
        fill: false,
        order: -1 * index,
        type: index === 0 ? 'bar': 'line',
        yAxisID: index === 0 ? 'y': 'y2',
      }))},
    [dataChart]
  );
  
  const data = {
    labels: labels,
    datasets: datasets,
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
  });
  let options = {
    ...commonOptions,
    scales: {
      y: {
        ...commonOptions.scales.y,
        stacked: true,
        type: 'linear',
        position: 'left',
      },
      x: {
        ...commonOptions.scales.x,
        stacked: true,
      },
      y2: {
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        ticks: {
            callback: function (value, index, values) {
                return tickFormatting(value);
            }
        },
        grid: {
          display: false
        }
      }
    },
  };
  return (
    <Bar
      type="bar"
      data={data}
      height={DEFAULT_HEIGHT}
      options={options}
      // onClick={getElementAtEvent}
    />
  );
};

const AreaChartRenderer = ({
  dataChart,
  labels,
  onDrilldownRequested,
}) => {
  const datasets = useDeepCompareMemo(
    () => {
      return dataChart.map((s, index) => ({
        label: s.shortTitle,
        data: s.series.map((r) => r.value),
        backgroundColor: PALE_COLORS_SERIES[index],
        borderColor: COLORS_SERIES[index],
        fill: true,
        order:2
      })).concat({
        label: 'Average',
        data: Array(dataChart.map(s => s.series.map(r => r.value))[0].length).fill(avg(dataChart.map(s => s.series.map(r => r.value))[0])),
        borderColor: '#c04b86a6',
        backgroundColor: '#c04b86a6',
        borderDash: [3],
        pointRadius: 0,
        order:1
      })
    },
    [dataChart]
  );
  const data = {
    labels: labels,
    datasets,
  };
  const options = {
    ...commonOptions,
    scales: {
      ...commonOptions.scales,
      y: {
        stacked: false,
        beginAtZero: true
      },
    },
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
  });
  return (
    <Line
      type="area"
      data={data}
      height={DEFAULT_HEIGHT}
      options={options}
      // onClick={getElementAtEvent}
    />
  );
};

const PieChartRenderer = ({ dataChart, labels, onDrilldownRequested }) => {
  const data = {
    labels: labels,
    datasets: dataChart.map((s) => ({
      label: s.shortTitle,
      data: s.series.map((r) => r.value),
      yValues: [s.key],
      backgroundColor: PALE_COLORS_SERIES,
      hoverBackgroundColor: COLORS_SERIES,
    })),
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
  });
  return (
    <Pie
      type="pie"
      data={data}
      height={DEFAULT_HEIGHT}
      options={commonOptions}
      // onClick={getElementAtEvent}
    />
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }
      return [...memo, column];
    }, []);
  }
  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return {
      ...memo,
      [column.dataIndex]: column,
    };
  }, {});
  function formatValue(value, { type, format, key } = {}) {
    if (value == undefined) {
      return '';
    }
    if (type === 'boolean') {
      if (typeof value === 'boolean') {
        return value.toString();
      } else if (typeof value === 'number') {
        return Boolean(value).toString();
      }
      return value;
    }
    if (type === 'number' && format === 'percent') {
      if(key === 'EventsCommentUnion.paywall_conversion_rate'){
        return makePercentage(value, 4);
      }
      return makePercentage(value, 2);
    }
    if (type === 'time' && value.includes('T00:00:00')){
      return moment(value).format('YYYY-MM-DD')
    }
    if (type === 'number'){
      return value.toLocaleString('en-US');
    }
    return value.toString();
  }
  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }
  return addIncrementalKey(data.map(format));
};

const formatColumns = (columns, filteredText) => {
  const arrayTextFields = columns.filter(col => col.type === 'string');
  return columns.map(col => {
    col['dataIndex'] = col['key']
    col['title'] = col['shortTitle']
    col['sorter'] = col['type'] === 'number' ? (a, b) => a[col['key']].replace('%', '').replace(',', '') - b[col['key']].replace('%', '').replace(',', '') :
                                               (a, b) => a[col['key']] > b[col['key']] ? 1 : a[col['key']] === b[col['key']] ? 0 : -1
    if (arrayTextFields.length > 0 && arrayTextFields.map(field => field.key).includes(col.key)){
      col['filteredValue'] = [filteredText]
      col['onFilter'] = (value, record) => arrayTextFields.map(field => String(record[field['key']]).toLowerCase().includes(value.toLowerCase())).includes(true);
    }
    if(col['format'] == 'imageUrl'){
      col['render'] = (theImageURL) => <Image alt={theImageURL} src={theImageURL} width={250}/>
    }
    if(col['format'] == 'link'){
      col['render'] = (URL) => <a onClick={()=> window.open(col['key'].split('.')[1] === 'url' && URL.includes('infographics') ?
                                                                         `https://multimedia.scmp.com${URL}` : 
                                                                         col['key'].split('.')[1] === 'url' ? `https://scmp.com${URL}` : URL, '_blank')}>{URL}</a>
    }
    // Make link to Article dashboard if col key contains article_id
    if(col['key'].split('.')[1] === 'article_id'){
      col['render'] = (articleID) => <AntdTooltip title="Click for Article Dashboard"><a onClick={()=> window.open(`https://exodus-cube.scmp.com/app/dashboards/article?article="${articleID}"`)}>{articleID}</a></AntdTooltip>
    }
    if(['avg_dau', 'avg_rl_dau'].includes(col['key'].split('.')[1])){
      col['render'] = (value) => parseFloat(value.replace(',', '')).toLocaleString('en-US',{
        maximumFractionDigits: 0,
      })
    }
    return col
  })
}

const TableRenderer = ({ data, columns, counter, mode, queryId }) => {
  const [searchedText, setSearchedText] = useState('');
  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    return [
      columns,
      formatTableData(columns, data),
    ];
  }, [data]);
  return (
    <div>
      <Row align="middle" >
        <Col span={20}>
          <Search 
            placeholder='Search here...' 
            style={{margin:'auto', marginBottom: 0, width: '25%'}}
            onSearch={(value) => setSearchedText(value)}
            onChange={(e) => setSearchedText(e.target.value)}
          >
          </Search>
        </Col>
        {mode === 'audience' && <Col span={4}>
          <Typography>The audience has <b>{counter} users</b></Typography>
        </Col>}
      </Row>
      <Table 
        id={`dataTable_${queryId}`}
        pagination={{
          position: data.length < 30 ? ['topRight'] : ['topRight', 'bottomRight'],
          pageSizeOptions: ['50','100','300','10000'],
          defaultPageSize:'50',
          total: data.length,
          showTotal: (total) => <p>Total <b>{total}</b> rows</p>
        }}
        columns={formatColumns(tableColumns, searchedText)} 
        dataSource={dataSource} 
        showSorterTooltip={false}
      />
    </div>
  );
};

const TypeToChartComponent = {
  line: ({ data, labels, colorSet, onDrilldownRequested }) => {
    return (
      <LineChartRenderer
        dataChart={data}
        labels={labels}
        colorSet={colorSet}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  bar: ({ data, labels, colorSet, onDrilldownRequested }) => {
    return (
      <BarChartRenderer
        dataChart={data}
        labels={labels}
        colorSet={colorSet}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  barLine: ({ data, labels, colorSet, onDrilldownRequested }) => {
    return (
      <BarLineChartRenderer
        dataChart={data}
        labels={labels}
        colorSet={colorSet}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  area: ({ data, labels, onDrilldownRequested }) => {
    return (
      <AreaChartRenderer
        dataChart={data}
        labels={labels}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  pie: ({ data, labels, onDrilldownRequested }) => {
    return (
      <PieChartRenderer
       dataChart={data}
        labels={labels}
        onDrilldownRequested={onDrilldownRequested}
      />
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: {DEFAULT_HEIGHT},
        }}
      >
        <Col>
          {resultSet && resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );
  },
  table: ({ data, columns, counter, mode, queryId }) => {
    return <TableRenderer data={data} columns={columns} counter={counter} mode={mode} queryId={queryId} />;
  },
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({
    ...a,
    ...b,
  }));

const renderChart = (Component) => ({ data, labels, columns, isLoading, error, colorSet, queryHasRan, mode, counter, queryId, updateQuery, GASchema }) =>
 (
    (data && data.length > 0 && !isLoading && queryHasRan && <Component data={data} labels={labels} columns={columns} colorSet={colorSet} counter={counter} mode={mode} queryId={queryId}/>) ||
    (data && data.length === 0 && !isLoading && queryHasRan && <Empty style={{marginTop: '160px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} />) ||
    (error && error.toString()) || 
    (isLoading && <Row type="flex" justify="center" align="middle" style={{marginTop: "160px"}}><Spin size="large"/></Row>) ||
    <Row type="flex" justify="center" align="middle">
      {mode === 'audience' ? 
      <Row type="flex" justify="center" align="middle" style={{marginTop: "30px"}}>
       <Col span={6}></Col>
       <Col span={22}>
          <Title style={{color: '#5B5C7D'}} level={3}>How does it work?</Title>
          <Steps
            direction="vertical"
            items={[
              {
                title: 'Define',
                description: 'Define your audience like you would author a query in Explore.',
                disabled: false,
                status: "process"
              },
              {
                title: 'Save',
                description: 'Save the audience like you would save a query. Give it a meaningful name!',
                disabled: false,
                status: "process"
              },
              {
                title: `Use`,
                description: 'You can hop to the Explore and find the saved audience under the PRE-DEFINED FILTERS.',
                disabled: false,
                status: "process"
              },
            ]}
          />
          </Col>
          <Col span={6}></Col>
        </Row> : mode === 'query' ? 
            <Tabs
              defaultActiveKey="1"
              centered
              tabBarStyle={{background: 'transparent'}}
              style={{background: 'none'}}
              items={[
                {
                  label: `Company KPIs`,
                  key: '1',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[16,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesCompanyKPIs(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Content Performance`,
                  key: '2',
                  children: <Row type="flex" justify="center" align="middle">
            
                            <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[16,16]}>
                                <Recommendations title="Basics" data={recommendedQueriesContentPerformance(GASchema)} type='query' updateQuery={updateQuery} />
                            </Row>
                          </Row>,
                },
                {
                  label: `Acquisition`,
                  key: '3',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesAcquisition(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Onsite Engagement`,
                  key: '4',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesOnsiteEngagement(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Subscriptions`,
                  key: '5',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesSubscriptions(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Newsletters`,
                  key: '6',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesNewsletters(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Push Alerts`,
                  key: '7',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesPushAlerts(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
                {
                  label: `Visits and Recirculation`,
                  key: '8',
                  children: <Row type="flex" justify="center" align="middle">
            
                              <Row align='middle' justify="space-between" style={{width: '90%', marginTop: 16}} gutter={[32,16]}>
                                  <Recommendations title="Basics" data={recommendedQueriesRecirculation(GASchema)} type='query' updateQuery={updateQuery} />
                              </Row>
                            </Row>,
                },
              ]}
            />: 
              <Row type="flex" justify="center" align="middle" style={{marginTop: "160px"}}>
                <Title style={{color: '#5B5C7D'}} level={3}>Start by building a query</Title>
              </Row>
      }
    </Row>
  )
    ;

const ChartRenderer = ({ isQueryPresent, isLoading, error, dataTable, columns, dataChart, labels, finalChart, colorSet, queryHasRan, updateQuery, GASchema, mode, counter, queryId }) => {
  const component = TypeToMemoChartComponent[finalChart];
  return (
    isQueryPresent && finalChart !== 'table' ? 
      renderChart(component)({isLoading, error, data: dataChart, columns, labels, colorSet, queryHasRan, mode, counter, queryId, updateQuery, GASchema }) : 
      renderChart(component)({isLoading, error, data: dataTable, columns, labels, colorSet, queryHasRan, mode, counter, queryId, updateQuery, GASchema })
  );
};

export default ChartRenderer;
