import React, { useEffect, useState } from 'react';
import { playgroundContext, buildApiUrl, platformBEtoFE } from '../../../config';
import { Space, Row, Col, Spin, Skeleton, Tabs, Typography, Button, Collapse, Statistic as AntStatistic, Input, Tooltip, Card as AntCard } from 'antd';
import { Card, Button as CustomButton, Container, Alert} from '../../../atoms';

import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { CategoryScale, LinearScale, Title as ChartTitle, Tooltip as ChartTooltip, Legend, BarElement, Chart as ChartJS } from "chart.js";
import { commonOptions, tickFormatting } from '../../ChartContainer/chartConfigs';
import { getRegionChartData, getTrafficSourceChartData, getPlatformChartData, getFirstTouchDeskChartData } from '../../Dashboards/FirstVisit/widgets';
import { 
    getRegionQuery, getArticlePlatformUnionQuery, getTrafficSourceQuery, getFirstTouchDeskQuery, 
  getExploreRegionQuery, getExplorePlatformQuery, getExploreTrafficSourceQuery, getExploreFirstTouchDeskQuery, getExploreAverageStoriesReadQuery
} from '../../Dashboards/FirstVisit/queries';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { findSecondLargest, isValidUrl, makePercentage, copyToClipboard } from '../../../utils';
import  { ExportOutlined, PieChartOutlined, LinkOutlined, AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';
import { useCubeQuery } from '@cubejs-client/react';
import { QueryContext } from '../../QueryContext';
import { useSecurityContext } from '../../../hooks';
import { useMetaContext } from '../../MetaContext';
import SectionRow  from '../../SectionRow';
import cube from '@cubejs-client/core';


const { Title, Link, Text } = Typography;
const { Panel } = Collapse;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  ChartTooltip,
  Legend,
  ChartDataLabels
);


const StyledCard = styled(Card)`
  margin: 8px 0px 0px;
  height: 380px;
  border: 1px solid #eee !important;
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &.ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
  
`;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: var(--dark-02-color);
    margin-bottom: 4px;
  }
`;

const Statistic = styled(AntStatistic)`
  &.ant-statistic-title {
    font-size: 36px;
    margin-bottom: 4px;
  }
`

export function FirstVisitPage({pageType}) {
  
    const [isLoading, setIsLoading] = useState(true);
    const [showTrafficSource, setShowTrafficSource] = useState(false);
    const [showDesk, setShowDesk] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPlatform, setShowPlatform] = useState(false);
    const [showTimeOnPlatform, setShowTimeOnPlatform] = useState(false);
    const [showAvgStoriesRead, setShowAvgStoriedRead] = useState(false);
  
    // In the order
    const [articleID, setArticleID] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const [limit, setLimit] = useState(1);

    const { currentToken, getUserDepartment } = useSecurityContext();
    const { basePath, baseUrl } = playgroundContext;
    const apiUrl = buildApiUrl(baseUrl, basePath);
    const department = getUserDepartment();
    const cubeApi = cube(currentToken, {apiUrl: apiUrl});
  
    const { meta, isLoading: isMetaLoading } = useMetaContext();
    const [metaExtended, setMetaExtended] = useState([]);

    const [regionQuery, setRegionQuery] = useState(getRegionQuery('', limit));
    const renderRegion = useCubeQuery(regionQuery, {skip: articleID.length === 0, cubeApi});

    const [trafficSourceQuery, setTrafficSourceQuery] = useState(getTrafficSourceQuery('', limit));
    const renderTrafficSource = useCubeQuery(trafficSourceQuery, {skip: articleID.length === 0, cubeApi});

    const [firstTouchDeskQuery, setFirstTouchDeskQuery] = useState(getFirstTouchDeskQuery('', limit));
    const renderFirstTouchDesk = useCubeQuery(firstTouchDeskQuery, {skip: articleID.length === 0, cubeApi});
    
    //BQ data
    const [regionData, setRegionData] = useState([]);
    const [regionLabels, setRegionLabels] = useState([]);
    const [averageStoriesRead, setAverageStoriesRead] = useState(null);
    const [firstTouchDeskData, setFirstTouchDeskData] = useState([]);
    const [firstTouchDeskLabels, setFirstTouchDeskLabels] = useState([]);
    const [platformData, setPlatformData] = useState([]);
    const [platformLabels, setPlatformLabels] = useState([]);
    const [trafficSourceData, setTrafficSourceData] = useState([]);
    const [trafficSourceLabels, setTrafficSourceLabels] = useState([]);
    const [averageTimeOnPlatform, setAverageTimeOnPlatform] = useState(null);


    useEffect(() => {
      if('resultSet' in renderTrafficSource && renderTrafficSource.resultSet){
        const data = renderTrafficSource.resultSet.loadResponse.results[0].data;
          if (data.length > 0){
            setTrafficSourceData(data.slice(0, 10).map(el => el['ArticleTrafficSourceAppleGAUnion.users']))
            setTrafficSourceLabels(data.slice(0, 10).map(el => el['ArticleTrafficSourceAppleGAUnion.traffic_source']))
          }
      }
    }, [renderTrafficSource.resultSet, renderTrafficSource.isLoading])


    useEffect(() => {
      setIsLoading(true)
      setTimeout(() => {
        if (pageType === 'all'){
          setTrafficSourceData([44346024, 22420373, 9371603, 4088990, 2958881, 2601454, 2300092, 2041860, 1384261, 1051182])
          setTrafficSourceLabels(['Organic Google', 'Direct', 'Discover', 'SmartNews referral', 'Others', 'Google Display Network', 'SCMP Facebook', 'Google News', 'RSS', 'Taboola'])
  
         
          setRegionData([23699540, 15073110, 12964851, 9170858, 6088143])
          setRegionLabels(['US', 'Asia', 'RoW2', 'RoW1', 'HK'])
  
          setFirstTouchDeskData([28218283, 9739229, 9134548, 7071338, 6091598, 5807819, 5008475, 3485305, 3160846, 2034528])
          setFirstTouchDeskLabels(['Style', 'Asia and TWIA', 'China', 'Lifestyle and Culture', 'Hong Kong', 'People & Culture', 'Sponsored Content', 'World', 'Sport', 'Economy'])
          setAverageStoriesRead(1.12)
          setAverageTimeOnPlatform(76.7)
  
          setPlatformData([40252756, 31378738, 24880771, 4045931, 241103])
          setPlatformLabels(['mobile web', 'Google AMP', 'desktop', 'offplatform', 'mobile app'])
        } else {
          setTrafficSourceData([156914, 144734, 21813, 8348, 8014, 5590, 5232, 4320, 4041, 2866])
          setTrafficSourceLabels(['Direct', 'Organic Google', 'Others', 'Google News', 'Paid Google', 'Google Display Network', 'Organic Bing', 'Taboola', 'Discover', 'Direct (via share widget)'])
          
          setRegionData([101977, 100343, 74518, 61530, 37446])
          setRegionLabels(['Asia', 'RoW2', 'US', 'HK', 'RoW1'])
  
          setFirstTouchDeskData([61869, 54357, 49455, 37202, 36604, 33760, 17994, 17154, 13012, 12971])
          setFirstTouchDeskLabels(['China', 'Style', 'Asia and TWIA', 'Hong Kong', 'Sponsored Content', 'Lifestyle and Culture', 'People & Culture', 'Economy', 'Tech', 'World'])
          setAverageStoriesRead(0.97)
          setAverageTimeOnPlatform(124)
  
          setPlatformData([219506, 132362, 21960, 8972, 32])
          setPlatformLabels(['mobile web', 'desktop', 'mobile app', 'Google AMP', 'offplatform'])
        }
        
        setIsLoading(false)
      }, 1500);
      
    }, [pageType])


    const Extra = ({query_obj}) => (
        <SectionRow>
          <Tooltip title="Open in Explore">
            <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}} href={`https://exodus-cube.scmp.com/app/?query=${query_obj}`}>
            <Button 
              type="secondary" 
              shape="round" 
              icon={<ExportOutlined />} 
            />
            </Link>
          </Tooltip>
        </SectionRow>
      );

    return (
    <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        <Col span={24}>
          <Row>
          {/* <Alert 
                message="Users are anonymous at this stage, we only track them thanks to their cookie set on the device. Therefore it is possible that real persons appear multiple times under different cookie IDs." 
                type="info" 
                showIcon
            /> */}
              <Col span={12}>
                <StyledCard 
                  title='First Touch (Desk)'
                  style={{marginRight: 4, marginTop: 0}}
                  onMouseEnter={() => setShowDesk(true)}
                  onMouseLeave={() => setShowDesk(false)}
                  extra={showDesk ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreFirstTouchDeskQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderFirstTouchDesk.isLoading ?  */
                      isLoading ?
                      <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <BarChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node></div> : 
                      <Bar 
                        options={{
                          scales: {
                            x: { display: false },
                            y: {
                              grid: {
                                display: false
                              }
                            }
                          },
                          indexAxis: 'y',
                          elements: {
                            bar: {
                              borderWidth: 2,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {display: false},
                            datalabels: {
                              display: true,
                              color: "black",
                              formatter: (value, ctx) => {return (value).toLocaleString('en-US')},
                              anchor: "center",
                              offset: 16,
                              align: "end",
                              font: {
                                size: 14
                              },
                            }
                          },
                        }} 
                        data={getFirstTouchDeskChartData(firstTouchDeskData, firstTouchDeskLabels)}
                        type='bar'
                        height={300}
                      />}
                </StyledCard>
                <StyledCard 
                  title='Region'
                  style={{marginRight: 4}}
                  onMouseEnter={() => setShowRegion(true)}
                  onMouseLeave={() => setShowRegion(false)}
                  extra={showRegion ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreRegionQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderRegion.isLoading ?  */
                      isLoading ?
                    
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <BarChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node></div> : 
                  <Pie
                    type="pie"
                    data={getRegionChartData(regionData, regionLabels)}
                    height={300}
                    options={
                        {
                          ...commonOptions,
                          maintainAspectRatio: false,
                          responsive: true,
                        plugins: {
                            legend: {
                              position: "right",
                              align: "middle",
                          },
                          datalabels: {
                            formatter: (value, ctx) => {
                              const datapoints = ctx.chart.data.datasets[0].data
                              const total = datapoints.reduce((total, datapoint) => total + Number(datapoint), 0)
                              const percentage = (value / total) * 100;
                              return percentage.toFixed(1) + "%";
                            },
                            display: (context) => {
                              return Number(getRegionChartData(regionData, regionLabels).datasets[0].data[context.dataIndex]) >= findSecondLargest(getRegionChartData(regionData, regionLabels).datasets[0].data.map(d => Number(d)));
                            },
                            font: {
                              size: 16
                            },
                            color: '#000',
                          },
                        },
                        scales: {
                          x: { display: false },
                          y: { display: false },
                        }
                    }}
                    // onClick={getElementAtEvent}
                  />}
                </StyledCard>
                <StyledCard 
                  title=''
                  style={{marginRight: 4}}
                  onMouseEnter={() => setShowAvgStoriedRead(true)}
                  onMouseLeave={() => setShowAvgStoriedRead(false)}
                  extra={showAvgStoriesRead ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreAverageStoriesReadQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderArticlePlatformUnion.isLoading ?  */
                      isLoading ?
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Skeleton.Node active></Skeleton.Node></div>
                    : 
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                      <div style={{fontSize: 48, fontWeight: 600}}>{averageStoriesRead}</div>
                      <div style={{fontSize: 16}}>stories read on average</div>
                    </div>}
                </StyledCard>
              </Col>
              <Col span={12}>
                <StyledCard 
                  title='Traffic Source'
                  style={{marginLeft: 4, marginTop: 0}}
                  onMouseEnter={() => setShowTrafficSource(true)}
                  onMouseLeave={() => setShowTrafficSource(false)}
                  extra={showTrafficSource ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreTrafficSourceQuery(articleID, dateRange)))} /> : <div></div>}
                >
                
                    {
                      /* renderTrafficSource.isLoading ?  */
                      isLoading ?
                      <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <BarChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node>
                      </div> : 
                      <Bar 
                        options={{
                          scales: {
                            x: { display: false },
                            y: {
                              grid: {
                                display: false
                              }
                            }
                          },
                          indexAxis: 'y',
                          elements: {
                            bar: {
                              borderWidth: 2,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {display: false},
                            datalabels: {
                              display: true,
                              color: "black",
                              formatter: (value, ctx) => {return (value).toLocaleString('en-US')},
                              anchor: "center",
                              offset: 16,
                              align: "end",
                              font: {
                                size: 14
                              },
                            }
                          },
                        }} 
                        data={getTrafficSourceChartData(trafficSourceData, trafficSourceLabels)}
                        type='bar'
                        height={300}
                      />
                    }
                </StyledCard>
                <StyledCard 
                  title='Platform'
                  style={{marginLeft: 4}}
                  onMouseEnter={() => setShowPlatform(true)}
                  onMouseLeave={() => setShowPlatform(false)}
                  extra={showPlatform ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExplorePlatformQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderFirstTouchDesk.isLoading ?  */
                      isLoading ?
                      <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <BarChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node></div> : 
                    <Pie
                      type="pie"
                      data={getPlatformChartData(platformData, platformLabels)}
                      height={300}
                      options={
                          {
                            ...commonOptions,
                            maintainAspectRatio: false,
                            responsive: true,
                          plugins: {
                              legend: {
                                position: "right",
                                align: "middle",
                            },
                            datalabels: {
                              formatter: (value, ctx) => {
                                const datapoints = ctx.chart.data.datasets[0].data
                                const total = datapoints.reduce((total, datapoint) => total + Number(datapoint), 0)
                                const percentage = (value / total) * 100;
                                return percentage.toFixed(1) + "%";
                              },
                              display: (context) => {
                                return Number(getPlatformChartData(platformData, platformLabels).datasets[0].data[context.dataIndex]) >= findSecondLargest(getPlatformChartData(platformData, platformLabels).datasets[0].data.map(d => Number(d)));
                              },
                              font: {
                                size: 16
                              },
                              color: '#000',
                            },
                          },
                          scales: {
                            x: { display: false },
                            y: { display: false },
                          }
                      }}
                      // onClick={getElementAtEvent}
                    />}
                </StyledCard>
                <StyledCard 
                  title=''
                  style={{marginLeft: 4}}
                  onMouseEnter={() => setShowTimeOnPlatform(true)}
                  onMouseLeave={() => setShowTimeOnPlatform(false)}
                  extra={showTimeOnPlatform ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreRegionQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderArticlePlatformUnion.isLoading ?  */
                      isLoading ?
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Skeleton.Node active></Skeleton.Node></div>
                    : 
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                      <div style={{fontSize: 48, fontWeight: 600}}>{averageTimeOnPlatform} sec</div>
                      <div style={{fontSize: 16}}>on platform on average</div>
                    </div>}
                </StyledCard>
              </Col>
          </Row>
        </Col>
        </QueryContext>
  );
}