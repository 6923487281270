import { advancedGASchemaToDrupalCube, advancedGASchemaToGAcube, advancedGASchemaToDrupalVideoCube, basicGASchemaToDrupalCube, basicGASchemaToGAcube } from "../../config";
import moment from "moment";

const fiscalYear = moment().month() < 3 ? moment().year() - 1 : moment().year();

export const recommendedQueriesCompanyKPIs = (GASchema) => {
  const gaCube = basicGASchemaToGAcube[GASchema];
  const drupalCube = basicGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Daily R&L Users and New Subs',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `Subscriptions.new_subscribers`], 
      timeDimensions: [{dimension: `${gaCube}.date`, granularity: 'day', dateRange: 'Last 30 days'}]
    },
    dateRange: 'past 30 days'
  },
  {
    title: 'Pageviews & Users (Overall)',
    query: {
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`], 
      dimensions: [], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last month'}]
    },
    dateRange: 'past month'
  },
  {
    title: 'Avg DAU and R&L DAU Trend (by Platform)',
    query: {
      measures: [`${gaCube}.avg_dau`, `${gaCube}.avg_rl_dau`], 
      dimensions: [`${gaCube}.platform`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 13 weeks', granularity: 'week',}]
    },
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Avg DAU and R&L DAU Trend (by Desk)',
    query: {
      measures: [`${gaCube}.avg_dau`, `${gaCube}.avg_rl_dau`], 
      dimensions: [`${drupalCube}.desk`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 13 weeks', granularity: 'week',}, {dimension: `${drupalCube}.publication_date`, dateRange: 'Last 13 weeks'}]
    },
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Avg DAU and R&L DAU Trend (by Traffic Source)',
    query: {
      measures: [`${gaCube}.avg_dau`, `${gaCube}.avg_rl_dau`], 
      dimensions: [`${gaCube}.traffic_source`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 13 weeks', granularity: 'week',}]
    },
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Avg DAU and R&L DAU Trend (by Region)',
    query: {
      measures: [`${gaCube}.avg_dau`, `${gaCube}.avg_rl_dau`], 
      dimensions: [`${gaCube}.region`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 13 weeks', granularity: 'week',}]
    },
    dateRange: 'past 13 weeks'
  },
]};

export const recommendedQueriesContentPerformance = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  const drupalVideoCube = advancedGASchemaToDrupalVideoCube[GASchema];
  return [
  {
    title: 'Top Articles for R&L Users',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `${gaCube}.users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.desk`, `${drupalCube}.first_topic`, `${drupalCube}.first_user_needs`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"},{dimension: `${drupalCube}.publication_date`, dateRange: "Last 7 days"}],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Top Topics for R&L Users (Excl. Style, Postmag, YP & Posties)',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.first_topic`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"},{dimension: `${drupalCube}.publication_date`, dateRange: "Last 7 days"}],
      filters: [
        {member: `${drupalCube}.desk`, operator: "notEquals", values: ["Style", "Post Magazine", "YP", "Posties"]}
      ],
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Top Videos by On-site Views 3s',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`}],
      dimensions: [`${gaCube}.video_youtube_id`, `${drupalCube}.title`, `${drupalVideoCube}.video_duration`],
      measures: [`${gaCube}.on_site_video_views_3s`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Top Series for R&L Users',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `${gaCube}.users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.series_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.desk`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 30 days"},{dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${drupalCube}.series_id`, operator: "set"}
      ],
    },
    schema: 'GA',
    dateRange: 'past 30 days'
  },
  {
    title: 'Top Evergreen Content (Lifestyle)',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `${gaCube}.users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.first_topic`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 30 days"},{dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${drupalCube}.desk`, operator: "equals", values: ["Lifestyle and Culture"]}
      ],
      segments: [`${gaCube}.article_is_evergreen`]
    },
    schema: 'GA',
    dateRange: 'past 30 days'
  },
  {
    title: 'Articles Published per User Need',
    query: {
      timeDimensions: [{dimension: `${drupalCube}.publication_date`, dateRange: `From April 1st ${fiscalYear} until now`}],
      dimensions: [`${drupalCube}.first_user_needs`],
      measures: [`${drupalCube}.articles`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};


export const recommendedQueriesAcquisition = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Monthly New Registrations',
    query: {
      measures: [`RegisteredUsers.accounts`], 
      dimensions: [], 
      timeDimensions: [{dimension: `RegisteredUsers.registration_date`, granularity: "month", dateRange: 'Last 6 months'}],
    },
    schema: 'RegisteredUsers',
    entity: 'account',
    dateRange: 'past 6 months'
  },
  {
    title: 'Paid Campaign ROI',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 2 weeks"}],
      filters: [
        {member: `${gaCube}.campaign`, operator: "startsWith", values: ["gme-i"]},
      ],
      dimensions: [`CampaignsUnion.c_platform`, `${gaCube}.campaign`],
      measures: [`${gaCube}.loyal_regular_users`, `Subscriptions.new_subscribers`, `CampaignsUnion.spent_hkd`]
    },
    entity: 'campaign',
    dateRange: 'past 2 weeks'
  },
  {
    title: 'App Download Paid Campaigns',
    query: {
      timeDimensions: [{dimension: `Appsflyer.app_install_time`, dateRange: "Last month"}],
      filters: [
        {member: `Appsflyer.appsflyer_campaign`, operator: "startsWith", values: ["gme-i"]}
      ],
      dimensions: [],
      measures: [`Appsflyer.app_downloads`]
    },
    entity: 'campaign',
    schema: 'Appsflyer',
    dateRange: 'past month'
  },
  {
    title: 'Daily Search Impressions & Click',
    query: {
      measures: [`SearchConsole.search_console_impressions`, `SearchConsole.search_console_organic_clicks`], 
      dimensions: [],
      timeDimensions: [{dimension: `SearchConsole.date`, dateRange: 'Last 6 months', granularity: 'day'}, {dimension: `DrupalSearchConsole.publication_date`, dateRange: 'Last 6 months'}],
      filters: [
        {member: `SearchConsole.search_type`, operator: "equals", values: ["web"]}
      ],
    },
    schema: 'GSC',
    dateRange: 'past 6 months'
  },
  {
    title: 'Daily Discover Impressions & Clicks',
    query: {
      measures: [`SearchConsole.search_console_impressions`, `SearchConsole.search_console_organic_clicks`], 
      dimensions: [],
      timeDimensions: [{dimension: `SearchConsole.date`, dateRange: 'Last 6 months', granularity: 'day'}, {dimension: `DrupalSearchConsole.publication_date`, dateRange: 'Last 6 months'}],
      filters: [
        {member: `SearchConsole.search_type`, operator: "equals", values: ["discover"]}
      ],
    },
    schema: 'GSC',
    dateRange: 'past 7 days'
  },
  {
    title: 'Search Performance per Article (City Desk)',
    query: {
      measures: [`SearchConsole.search_console_impressions`, `SearchConsole.search_console_organic_clicks`], 
      dimensions: [`DrupalSearchConsole.article_id`, `DrupalSearchConsole.title`, `DrupalSearchConsole.publication_date`, `DrupalSearchConsole.first_topic`, `DrupalSearchConsole.first_user_needs`],
      timeDimensions: [{dimension: `SearchConsole.date`, dateRange: 'Last 30 days'}, {dimension: `DrupalSearchConsole.publication_date`, dateRange: 'Last 30 days'}],
      filters: [
        {member: `DrupalSearchConsole.desk`, operator: "equals", values: ["Hong Kong"]}
      ],
    },
    entity: 'article',
    schema: 'GSC',
    dateRange: 'past 30 days'
  },
]};


export const recommendedQueriesOnsiteEngagement = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Article Completion Rates (by Desk)',
    query: {
      measures: [`${gaCube}.article_pageviews_fifty_percent_per_article_pageviews`, `${gaCube}.article_pageviews_hundred_percent_per_article_pageviews`], 
      dimensions: [`${drupalCube}.desk`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 3 weeks'},{dimension: `${drupalCube}.publication_date`, dateRange: 'Last 3 weeks'}],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Article Completion Rates (by User Need)',
    query: {
      measures: [`${gaCube}.article_pageviews_fifty_percent_per_article_pageviews`, `${gaCube}.article_pageviews_hundred_percent_per_article_pageviews`], 
      dimensions: [`${drupalCube}.first_user_needs`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 3 weeks'},{dimension: `${drupalCube}.publication_date`, dateRange: 'Last 3 weeks'}],
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Article Shares (by Desk)',
    query: {
      measures: [`${gaCube}.social_shares`], 
      dimensions: [`${drupalCube}.desk`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 2 weeks'},{dimension: `${drupalCube}.publication_date`, dateRange: 'Last 2 weeks'}],
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Avg Sessions per User (by Platform)',
    query: {
      measures: [`${gaCube}.sessions_per_users`], 
      dimensions: [`${gaCube}.platform`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 13 weeks', granularity: 'week'}],
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Comments (by Desk)',
    query: {
      measures: [`CommentsGA4.comments`], 
      dimensions: [`${drupalCube}.desk`],
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 3 weeks'}, {dimension: `${drupalCube}.publication_date`, dateRange: 'Last 3 weeks'}],
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Daily Follow Topics',
    query: {
      measures: [`${gaCube}.follow_topics`], 
      dimensions: [], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 3 weeks', granularity: 'day'}],
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};


export const recommendedQueriesSubscriptions = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Last Touch Articles',
    query: {
      measures: [`Subscriptions.new_subscribers`], 
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.authors_name`, `${drupalCube}.first_topic`, `${drupalCube}.first_user_needs`], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Last 30 days'},{dimension: `${drupalCube}.publication_date`}],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Weekly New Starts & Churn',
    query: {
      measures: [`Subscriptions.new_subscribers`, `Subscriptions.churn`], 
      dimensions: [], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Last 13 weeks', granularity: 'week'}],
      filters: [
        {member: `Subscriptions.subs_product`, operator: "equals", values: ["Digital", "Digital + Bundle", "Plus"]}
      ],
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Current B2C HK Subscribers (by Plan by Product)',
    query: {
      measures: [`Subscriptions.subscribers`], 
      dimensions: [`Subscriptions.subscription_plan`, `Subscriptions.subs_product`], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Yesterday'}, {dimension: `RegisteredUsers.registration_date`}],
      filters: [
        {member: `Subscriptions.subs_product`, operator: "equals", values: ["Digital", "Digital + Bundle", "Plus"]},
        {member: `RegisteredUsers.country_at_subscription`, operator: "equals", values: ["Hong Kong"]},
        {member: `Subscriptions.source_dataset`, operator: "contains", values: ["paywall"]},
      ],
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Paywall Conversion Rate & Funnel',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 3 months"}],
      filters: [
        {member: `${gaCube}.trigger_point`, operator: "contains", values: ["wall"]},
      ],
      dimensions: [`Events.trigger_point`],
      measures: [`${gaCube}.paywall_conversion_rate`, `${gaCube}.paywall_impressions`, `${gaCube}.paywall_clicks`, `Subscriptions.new_subscribers`]
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'B2C Subscribers (by Product)',
    query: {
      measures: [`Subscriptions.subscribers`], 
      dimensions: [`Subscriptions.subs_product`], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Yesterday'}],
      filters: [
        {member: `Subscriptions.subs_product`, operator: "equals", values: ["Digital", "Digital + Bundle", "Plus"]},
        {member: `Subscriptions.subscription_plan`, operator: "notEquals", values: ["FREE TRIAL"]},
        {member: `Subscriptions.source_dataset`, operator: "contains", values: ["paywall"]},
      ],
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Plus: Free to Paid Subscribers',
    query: {
      measures: [], 
      dimensions: [`RegisteredUsers.user_id`, `RegisteredUsers.country_at_subscription`, `RegisteredUsers.loyalty_at_subscription`, `Subscriptions.subscription_plan`], 
      timeDimensions: [
        {dimension: `Subscriptions.active_date`, dateRange: 'Last 3 months'}, 
        {dimension: `RegisteredUsers.registration_date`},
        {dimension: `Subscriptions.subs_start_date`, granularity: "day"},
      ],
      filters: [
        {member: `Subscriptions.subs_product`, operator: "equals", values: ["Plus"]},
        {member: `Subscriptions.last_sub_plan`, operator: "equals", values: ["FREE TRIAL"]},
        {member: `Subscriptions.plan_change`, operator: "equals", values: ["Upgrade"]},
      ],
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};


export const recommendedQueriesNewsletters = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Editorial NL Performance (Sent, Open, Click)',
    query: {
      measures: [`NewsletterEvents.emails_sent`, `NewsletterEvents.email_opens`, `NewsletterEvents.email_clicks`], 
      dimensions: [`NewsletterDetails.newsletter_name`], 
      timeDimensions: [{dimension: `NewsletterEvents.newsletter_action_date`, dateRange: 'Last month'},{dimension: `NewsletterDetails.newsletter_send_date`, dateRange: 'Last month'}],
      filters: [
        {member: `NewsletterDetails.newsletter_email_type`, operator: "equals", values: ["newsletter"]},
      ],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'MyNews Email Campaign Performance',
    query: {
      measures: [`NewsletterEvents.email_open_rate`, `NewsletterEvents.email_click_to_open_rate`, `NewsletterEvents.email_click_through_rate`, `NewsletterEvents.email_opens`, `NewsletterEvents.email_clicks`], 
      dimensions: [`NewsletterDetails.newsletter_campaign_name`], 
      timeDimensions: [{dimension: `NewsletterEvents.newsletter_action_date`, dateRange: 'Last 30 days'},{dimension: `NewsletterDetails.newsletter_send_date`, dateRange: 'Last 30 days'}],
      filters: [
        {member: `NewsletterDetails.newsletter_name`, operator: "equals", values: ["myNEWS"]},
      ],
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Global Impact: NL Subscribers',
    query: {
      timeDimensions: [{dimension: `RegisteredUsers.registration_date`}],
      filters: [
        {member: `RegisteredUsers.newsletter_user_list`, operator: "equals", values: ["SCMP Global Impact"]}
      ],
      dimensions: [],
      measures: [`RegisteredUsers.accounts`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'NL Subscribers (Paid Subscribers Only)',
    query: {
      timeDimensions: [{dimension: `RegisteredUsers.registration_date`}],
      filters: [
        {member: `RegisteredUsers.user_role`, operator: "equals", values: ["Subscriber"]}
      ],
      dimensions: [`RegisteredUsers.newsletter_user_list`],
      measures: [`RegisteredUsers.accounts`]
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'New NL Subs (by Page Category)',
    query: {
      measures: [`${gaCube}.nl_signup`], 
      dimensions: [`${gaCube}.page_category`],
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 3 weeks'}],
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};


export const recommendedQueriesPushAlerts = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Push Alert Performance (by Article)',
    query: {
      measures: [`BatchEvents.push_notifications_received`, `BatchEvents.push_notification_opens`, `BatchEvents.push_notification_open_rate`], 
      dimensions: [`DrupalBatch.article_id`, `DrupalBatch.title`, `DrupalBatch.publication_date`, `DrupalBatch.desk`], 
      timeDimensions: [{dimension: `BatchEvents.event_date`, dateRange: 'Last 7 days'},{dimension: `DrupalBatch.publication_date`, dateRange: 'Last 7 days'}],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Push Alert Performance (by Channel)',
    query: {
      measures: [`BatchEvents.push_notifications_received`, `BatchEvents.push_notification_opens`, `BatchEvents.push_notification_open_rate`], 
      dimensions: [`BatchChannels.push_channel`], 
      timeDimensions: [{dimension: `BatchEvents.event_date`, dateRange: 'Last 7 days'},{dimension: `BatchCampaigns.push_time`, dateRange: 'Last 7 days'}],
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Daily Push Campaign Performance',
    query: {
      measures: [`BatchEvents.push_notifications_received`, `BatchEvents.push_notification_opens`, `BatchEvents.push_notification_open_rate`], 
      dimensions: [], 
      timeDimensions: [{dimension: `BatchEvents.event_date`, dateRange: 'Last 7 days', granularity: 'day'},{dimension: `BatchCampaigns.push_time`, dateRange: 'Last 7 days'}],
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Daily App Notification Opt-ins/Outs',
    query: {
      measures: [`BatchEvents.push_notification_opt_ins`, `BatchEvents.push_notification_opt_outs`], 
      dimensions: [], 
      timeDimensions: [{dimension: `BatchEvents.event_date`, dateRange: 'Last 3 months', granularity: 'day'}],
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Push Alert AB Test Performance',
    query: {
      measures: [`BatchEvents.push_notifications_received`, `BatchEvents.push_notification_opens`, `BatchEvents.push_notification_open_rate`], 
      dimensions: [`BatchCampaigns.push_campaign_name`, `BatchCampaigns.push_time`, `BatchCampaigns.variant`, `BatchCampaigns.body`], 
      timeDimensions: [{dimension: `BatchCampaigns.push_time`, dateRange: 'Last 30 days'}],
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};


export const recommendedQueriesRecirculation = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Top Homepage Article Clicks',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${gaCube}.previous_page_category`, operator: "equals", values: ["Homepage"]}
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.desk`, `${drupalCube}.first_topic`],
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`]
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Top Recirculation Articles',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`, dateRange: "Last 7 days"}],
      filters: [
        {member: `${gaCube}.article_pageviews`, operator: "gt", values: ["100"]}
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.desk`, `${drupalCube}.first_topic`, `${drupalCube}.first_user_needs`],
      measures: [`${gaCube}.exit_rate`, `${gaCube}.users`, `${gaCube}.loyal_regular_users`],
      order: {[`${gaCube}.exit_rate`]: "asc"},
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Homepage Widgets Performance',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}],
      filters: [
        {member: `${gaCube}.page_category`, operator: "equals", values: ["Homepage"]}
      ],
      dimensions: [`${gaCube}.widget_name`],
      measures: [`${gaCube}.recirculation_widget_ctr`, `${gaCube}.recirculation_widget_impressions`, `${gaCube}.recirculation_widget_clicks`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Article Widgets Performance',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}],
      filters: [
        {member: `${gaCube}.page_category`, operator: "equals", values: ["Article"]}
      ],
      dimensions: [`${gaCube}.widget_name`],
      measures: [`${gaCube}.recirculation_widget_ctr`, `${gaCube}.recirculation_widget_impressions`, `${gaCube}.recirculation_widget_clicks`]
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'Top Facebook Article Clicks',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 3 months"}, {dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${gaCube}.previous_page`, operator: "equals", values: ["entrance"]},
        {member: `${gaCube}.traffic_source`, operator: "equals", values: ["Organic Facebook", "Paid Facebook", "SCMP Facebook"]}
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.first_topic`],
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`]
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};