import { Input } from 'antd';
import { debounce } from 'throttle-debounce';
import { useRef, useState } from 'react';

import { Select }  from '../../atoms/Select';
import moment from 'moment';
import { useFilterValuesContext } from '../FilterValuesContext';
import Calendar from '../../atoms/Calendar';

const FilterInputs = {
  string: ({ values = [], disabled, onChange, options = [], operator }) => (
    <Select
      key="input"
      disabled={disabled}
      style={{ width: 350 }}
      mode={"tags"}
      value={values.map(val => val.replace('\t\r', ''))}
      maxTagCount="responsive"
      tokenSeparators={[',', '\n']}
      onChange={onChange}
      options={values.map(val => val.replace('\t\r', '')).map(val => ({value: val, label: val})).concat(options.filter(opt => !values.includes(opt.value)))}
    />
  ),
  number: ({ values = [], disabled, onChange }) => (
    <Input
      key="input"
      disabled={disabled}
      style={{ width: 300 }}
      onChange={(e) => onChange([e.target.value])}
      value={values?.[0] || ''}
    />
  ),
  time: ({ values = [], disabled, onChange }) => (
    <Calendar
      disabled={disabled}
      value={values.map(val => moment(val))}
      onChange={onChange}
    />
  ),
};

export default function FilterInput({
  member,
  disabled = false,
  updateMethods,
}) {
  const Filter = FilterInputs[member.dimension.type] || FilterInputs.string;
  const { filterValues } = useFilterValuesContext();
  const [values, setValues] = useState(member.values);

  const ref = useRef(
    debounce(500, (member, values) => {
      updateMethods.update(member, { ...member, values });
    })
  );

  return (
    <Filter
      key="filter"
      disabled={disabled}
      values={values}
      options={filterValues[member.member]}
      operator={member.operator}
      onChange={(values) => {
        setValues(values);
        ref.current(member, values);
      }}
    />
  );
}