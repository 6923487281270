import React, { memo } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
} from '@xyflow/react';
 
 
const EngagedNode = ({ data }) => {
  return (
    <div >
      <Row justify="center">
        <Statistic title={data.label} value={36087} valueStyle={{fontSize: 16, color: '#001246'}}/>
        <Typography style={{color: '#001246'}}>incl. 3978 subscribers</Typography>
      </Row>
      <Handle type="target" position={Position.Bottom} id="target" />
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" style={{top: '24px'}}/>
      <Handle type="source" position={Position.Right} id="right-source" style={{top: '72px'}}/>
      
    </div>
  );
};
 
export default memo(EngagedNode);