import { useState } from 'react';
import { List as AntdList, Typography, Card, Badge, Space, Row, Col } from 'antd';
import  { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQueryContext } from '../QueryContext';

const { Title, Text } = Typography;

const List = styled(AntdList)`
&& {
  .ant-list-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
`;

const StyledCard = styled(Card)`
  margin: 8px;
  border-radius: 6px;
  background-color: rgba(221,230,255, 0.4);
  border-radius: 120px;
  text-align: center;
  border-color: transparent;
  
  width: fit-content;
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &.ant-card-hoverable {
    overflow: auto;
    position: relative;
    &:hover {
      background-color: rgba(221,230,255, 0.8);
      box-shadow: none;
      transition: 0.3s;
    }
    
  }
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 8px;
  }
  ${'' /* @media only screen and (max-width: 1800px) {
      height: 98px;
  } */}
`;


export default function Recommendations({
  title,
  data,
  type,
  updateQuery,
}) {
  const { push } = useHistory();
  const { setGASchema } = useQueryContext();
  const [clicked, setClicked] = useState('');

  const handleClickOpen = (item) => {
    setClicked(item.title);
    updateQuery(item.query);
    if ('schema' in data){
        setGASchema(data.schema);
    }
    push({ search: `?query=${encodeURIComponent(JSON.stringify(item.query))}` });
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
  }

  return (
    <Row justify={'center'} align={'middle'} style={{width: '100%'}}>
      <List
          size="small"
          dataSource={data}
          renderItem={(item) => (
            clicked === item.title ? 
            <StyledCard hoverable={true} style={{backgroundColor: 'rgba(221,230,255, 0.8)', borderColor: '#3f88fe'}} onClick={() => handleClickOpen(item)}> 
              <Text>{item.title}</Text>
            </StyledCard> :
            <StyledCard hoverable={true} onClick={() => handleClickOpen(item)}> 
              <Text>{item.title}</Text>
            </StyledCard>
          )}
        />
    </Row>
  );
}