import React, { lazy, Suspense, useState } from 'react';
import {
  CopyOutlined,
  QuestionCircleOutlined,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import { Spin, Row, Typography, notification } from 'antd';
import styled from 'styled-components';
import { CLASSIC_CYCLIC_13_COLORS, OFFICE_BADGE_6_COLORS, PALE_COLORS_SERIES, COLORS_SERIES} from './chartConfigs';

import SectionRow from '../../components/SectionRow';
import { Button, Card, Alert } from '../../atoms';
import { copyToClipboard } from '../../utils';
import PrismCode from  './PrismCode';
import ChartRenderer from  './ChartRenderer';
import { useDeepEffect } from '../../hooks';
import { useQueryContext } from '../QueryContext';
import { fromTrafficToLastTouch } from '../../config';

const { Text } = Typography;
const SqlQueryTab = lazy(() => import('./SqlQueryTab'));

const StyledCard = styled(Card)`
  min-height: 420px;
  margin-top: 0px;
  .ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  .ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
`;


const ChartContainer = ({ 
  dryRunQuery, 
  queryToRun, 
  error, 
  isFetchingMeta, 
  chartType, 
  chartOption, 
  isQueryPresent, 
  dataTable, 
  setDataTable,
  columns,
  setColumns,
  clearQuery, 
  setClearQuery,
  showCode,
  setShowCode,
  renderProps,
  isSubDimsLimited,
  setIsSubDimsLimited,
  updateQuery,
  handleClearQuery,
  GASchema,
  mode,
  metaExtended,
  counter
}) => {

  const [chartRendererError, setChartRendererError] = useState(null);
  const [sql, setSql] = useState({ loading: false });
  const [title, setTitle] = useState(null);
  
  const [labels, setLabels] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [finalChart, setFinalChart] = useState('table');
  const [colorSet, setColorSet] = useState(COLORS_SERIES);
  const { schemaChangedByUser } = useQueryContext();

  useDeepEffect(() => {
    setFinalChart(chartOption === 'table' ? 'table': chartType);
  }, [chartType, chartOption])

  useDeepEffect(() => {
    if (clearQuery){
      setColumns([]);
      setLabels([]);
      setDataChart([]);
      setDataTable([]);
      setIsSubDimsLimited(false);
      setClearQuery(false);
    }
  }, [clearQuery])

  useDeepEffect(() => {
    if (!isFetchingMeta && Object.keys(dryRunQuery).length > 0 && schemaChangedByUser > 0) {
      handleClearQuery(dryRunQuery, updateQuery)
    }
  }, [GASchema, mode])

  // useDeepEffect(() => {
  //   window.addEventListener("keydown", onKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", onKeyDown);
  //   };
  // }, [dryRunQuery]) 

  // const onKeyDown = (e) => {
  //   if(e.metaKey && e.which === 13) {
  //     console.log('dryRunQuery', dryRunQuery)
  //     handleRunButtonClick({query: dryRunQuery, chartType});
  //   }
  // }

  // Post-process before rendering charts
  useDeepEffect(() => {
    if('resultSet' in renderProps && renderProps.resultSet){
      if(renderProps.resultSet.loadResponse.results[0].external){
        notification.open({
          message: 'This query is pre-aggregated',
          icon: <ThunderboltTwoTone />,
        });
      }
      // let metaAllDimsAllMeasures = metaExtended.map(cube => [...cube.dimensions, ...cube.measures]).flat();
      let pivotConfig = undefined;
      const data = renderProps.resultSet.loadResponse.results[0].data;
      if (chartType === 'bar' && 'dimensions' in queryToRun && queryToRun.dimensions.length === 2){
        if('segments' in queryToRun && queryToRun.segments.includes('Subscriptions.is_last_touch')){
          pivotConfig = {x: [fromTrafficToLastTouch(queryToRun.dimensions[0], GASchema)], y: [fromTrafficToLastTouch(queryToRun.dimensions[1], GASchema), 'measures']};
        } else {
          const responseDimensions = data.length > 0  ? Object.keys(data[0]).filter(col => !queryToRun.measures.includes(col) && !queryToRun.timeDimensions.map(td => td.dimension).includes(col)) : []
          pivotConfig = {x: [responseDimensions[0]], y: [responseDimensions[1], 'measures']};
        }
      }
      let columns = renderProps.resultSet.tableColumns();
      // const newColumns = columns.map(col => {
      //   const match = metaAllDimsAllMeasures.find(o => 'meta' in o && o.meta.view === col.meta.view && o.name.split('.')[1] === col.key.split('.')[1])
      //   console.log(match)
      //   return {...col, format: match.format, title: match.title, shortTitle: match.shortTitle};
      // })

      setLabels(renderProps.resultSet.categories(pivotConfig).map(c => c.x))
      setColumns(columns)
      setDataTable(renderProps.resultSet.tablePivot())
      // post-process data before render
      let secondDimValues = [];
      const counts = {};
      if (chartType === 'bar' && 'dimensions' in queryToRun && queryToRun.dimensions.length === 2 || chartType === 'line' && 'dimensions' in queryToRun && queryToRun.dimensions.length === 1){
        if (data.length > 0){
          const secondDim = Object.keys(data[0]).filter(col => queryToRun.dimensions.map(dim => dim.split('.')[1]).includes(col.split('.')[1]))[chartType === 'bar' ? 1 : 0];
          const metric = queryToRun.measures[0];
          data.forEach((row) => {
            counts[row[secondDim]] = counts[row[secondDim]] ? (counts[row[secondDim]] + row[metric]) : row[metric];
          });
          secondDimValues = Object.keys(Object.fromEntries(
            Object.entries(counts).sort(([,a],[,b]) => b-a).slice(0, 12)
          ));
        }
        setIsSubDimsLimited(Object.keys(counts).length > 12);
        if(Object.keys(counts).length > 12){
          setDataChart(renderProps.resultSet.series(pivotConfig).filter(row => (secondDimValues || []).includes(row.key.split(',')[0])))
        } else {
          setDataChart(renderProps.resultSet.series(pivotConfig))
        }
      } else {
        if (chartType !== 'table'){
          setDataChart(renderProps.resultSet.series(pivotConfig))
        }
      }
      if (chartType === 'bar'){
        setColorSet('dimensions' in queryToRun && queryToRun.dimensions.length === 2 ? CLASSIC_CYCLIC_13_COLORS : PALE_COLORS_SERIES);
      }
      if (chartType === 'line'){
        setColorSet('dimensions' in queryToRun && queryToRun.dimensions.length === 1 ? OFFICE_BADGE_6_COLORS : PALE_COLORS_SERIES);
      }
    }
  }, [renderProps.resultSet])


  const queryText = JSON.stringify(dryRunQuery, null, 2);

  const renderChart = () => {
    // console.log(error)
    // if (error) {
    //   return <Alert
    //   type="error"
    //   message={
    //     error.message.replace('measures', 'metrics')
    //   }
    // />;
    // }

    if (chartOption === 'query') {
      
      return <div>
          <Button
            data-testid="copy-cube-query-btn"
            icon={<CopyOutlined />}
            size="small"
            onClick={async () => {
              await copyToClipboard(JSON.stringify(dryRunQuery, null, 2), 'The JSON query has been copied');
            }}
            type="primary"
          >
            Copy to Clipboard
          </Button>
          <PrismCode code={queryText} />
      </div>

    } else if (chartOption === 'sql') {
      return (
        <Suspense
          fallback={
            <Row type="flex" justify="center" align="middle" style={{marginTop: "120px"}}>
              <Spin size="large"/>
            </Row>
          }
        >
        {!sql.loading && sql.value ? (
            <Button
              data-testid="copy-sql-btn"
              icon={<CopyOutlined />}
              size="small"
              onClick={async () => {
                await copyToClipboard(sql.value, 'The SQL has been copied');
              }}
              type="primary"
            >
              Copy to Clipboard
            </Button>
          ) : null}
          <SqlQueryTab
            query={dryRunQuery}
            onChange={(sql) => setSql(sql)}
          />
        </Suspense>
      );
    }

    return (
      <ChartRenderer
        dataTable={dataTable}
        dataChart={dataChart}
        columns={columns}
        labels={labels}
        finalChart={finalChart}
        colorSet={colorSet}
        isLoading={renderProps.isLoading}
        error={renderProps.error}
        isQueryPresent={isQueryPresent}
        queryHasRan={renderProps.resultSet ? true: false}
        updateQuery={updateQuery}
        GASchema={GASchema}
        mode={mode}
        counter={counter}
        queryId={renderProps.id}
      />
    );
  };

  return (
    <StyledCard title={title}>
      {renderChart()}
    </StyledCard>
  );
}

export default ChartContainer;