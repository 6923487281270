import React, { useEffect, useState } from 'react';
import { playgroundContext, buildApiUrl, platformBEtoFE } from '../../../config';
import { Space, Row, Col, Select, Skeleton, Tabs, Typography, Button, Collapse, Statistic as AntStatistic, Input, Tooltip, Card as AntCard } from 'antd';
import { Card, Button as CustomButton, Container, Alert} from '../../../atoms';

import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { CategoryScale, LinearScale, Title as ChartTitle, Tooltip as ChartTooltip, Legend, BarElement, Chart as ChartJS } from "chart.js";
import { commonOptions, tickFormatting } from '../../ChartContainer/chartConfigs';
import { getRegionChartData, getNewRegistrationsChartData, getPlatformChartData, getRegistrationEntryPointChartData } from '../../Dashboards/Registration/widgets';
import { 
    getRegionQuery, getArticlePlatformUnionQuery, getNewRegistrationsQuery, getRegistrationEntryPointQuery, 
  getExploreRegionQuery, getExplorePlatformQuery, getExploreNewRegistrationsQuery, getExploreRegistrationEntryPointQuery, getExploreConversionRateQuery
} from '../../Dashboards/Registration/queries';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { findSecondLargest, isValidUrl, makePercentage, copyToClipboard } from '../../../utils';
import  { ExportOutlined, PieChartOutlined, LinkOutlined, AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';
import { useCubeQuery } from '@cubejs-client/react';
import { QueryContext } from '../../QueryContext';
import { useSecurityContext } from '../../../hooks';
import { useMetaContext } from '../../MetaContext';
import SectionRow  from '../../SectionRow';
import cube from '@cubejs-client/core';


const { Title, Link, Text } = Typography;
const { Panel } = Collapse;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  ChartTooltip,
  Legend,
  ChartDataLabels
);


const StyledCard = styled(Card)`
  margin: 8px 0px 0px;
  height: 380px;
  border: 1px solid #eee !important;
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &.ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
  
`;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: var(--dark-02-color);
    margin-bottom: 4px;
  }
`;

const Statistic = styled(AntStatistic)`
  &.ant-statistic-title {
    font-size: 36px;
    margin-bottom: 4px;
  }
`

export function RegistrationPage({pageType}) {
  
    const [isLoading, setIsLoading] = useState(true);
    const [showNewRegistrations, setShowNewRegistrations] = useState(false);
    const [showEntryPoint, setShowEntryPoint] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPlatform, setShowPlatform] = useState(false);
    const [showTimeOnPlatform, setShowTimeOnPlatform] = useState(false);
    const [showAvgStoriesRead, setShowConversionRate] = useState(false);
  
    // In the order
    const [articleID, setArticleID] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const [limit, setLimit] = useState(1);

    const { currentToken, getUserDepartment } = useSecurityContext();
    const { basePath, baseUrl } = playgroundContext;
    const apiUrl = buildApiUrl(baseUrl, basePath);
    const department = getUserDepartment();
    const cubeApi = cube(currentToken, {apiUrl: apiUrl});
  
    const { meta, isLoading: isMetaLoading } = useMetaContext();
    const [metaExtended, setMetaExtended] = useState([]);

    const [regionQuery, setRegionQuery] = useState(getRegionQuery('', limit));
    const renderRegion = useCubeQuery(regionQuery, {skip: articleID.length === 0, cubeApi});

    const [newRegistrationsQuery, setNewRegistrationsQuery] = useState(getNewRegistrationsQuery('', limit));
    const renderNewRegistrations = useCubeQuery(newRegistrationsQuery, {skip: articleID.length === 0, cubeApi});

    const [registrationEntryPointQuery, setRegistrationEntryPointQuery] = useState(getRegistrationEntryPointQuery('', limit));
    const renderRegistrationEntryPoint = useCubeQuery(registrationEntryPointQuery, {skip: articleID.length === 0, cubeApi});
    
    //BQ data
    const [conversionRate, setConversionRate] = useState(null);
    const [registrationEntryPointData, setRegistrationEntryPointData] = useState([]);
    const [registrationEntryPointLabels, setRegistrationEntryPointLabels] = useState([]);
    const [newRegistrationsData, setNewRegistrationsData] = useState([]);
    const [newRegistrationsLabels, setNewRegistrationsLabels] = useState([]);
    const [averageDaysFirstVisitRegistration, setAverageDaysFirstVisitRegistration] = useState(null);


    useEffect(() => {
      if('resultSet' in renderNewRegistrations && renderNewRegistrations.resultSet){
        const data = renderNewRegistrations.resultSet.loadResponse.results[0].data;
          if (data.length > 0){
            setNewRegistrationsData(data.slice(0, 10).map(el => el['ArticleNewRegistrationsAppleGAUnion.users']))
            setNewRegistrationsLabels(data.slice(0, 10).map(el => el['ArticleNewRegistrationsAppleGAUnion.traffic_source']))
          }
      }
    }, [renderNewRegistrations.resultSet, renderNewRegistrations.isLoading])

    function generateDateArray(startDate, endDate) {
      const dateArray = [];
      let currentDate = new Date(startDate);
    
      while (currentDate <= new Date(endDate)) {
        // Format the date as YYYY-MM-DD
        const formattedDate = currentDate.toISOString().split('T')[0];
        dateArray.push(formattedDate);
    
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    
      return dateArray;
    }
    

    useEffect(() => {
      setIsLoading(true)
      setTimeout(() => {
        if (pageType === 'traffic'){
          setNewRegistrationsData([
            2067, 2288, 2155, 2345, 1976, 1664, 1559, 1664, 1712, 1487,
            1694, 2015, 2159, 2201, 3900, 3981, 2483, 1644, 2329, 1926,
            1662, 1730, 2229, 1656, 1276, 1897, 1739, 1678, 1461, 1838,
            1784, 1817, 1758, 1707, 1722, 2169, 2165, 2065, 2397, 2734,
            2145, 1798, 1837, 1783, 1420, 1544, 2288, 2028, 2025, 2126,
            2290, 2399, 2114, 2138, 2072, 2080, 2074, 1934, 1501, 1499,
            2121, 2071, 2152, 1826, 1851, 1584, 1792, 2204, 2277, 2034,
            2232, 1984, 2164, 2022, 2265, 2199, 2335, 2685, 2142, 1583,
            1879, 2810, 2556, 2391, 2838, 3322, 1837, 1689, 2344, 2502,
            2222, 2287, 2423, 2138, 2562, 2722, 2521, 2777, 2392, 2586,
            2030, 1897, 2589, 2282, 2234, 2469, 1855, 1593, 2017, 3074,
            2822, 2200, 1819, 1797, 1555, 1627, 2120, 2060, 1964, 1888,
            1797, 1687, 1758, 2050, 2447, 2245, 2273, 1901, 1667, 1556,
            2314, 2128, 2863, 2468, 2008, 1863, 1895, 2553, 2625, 1786,
            2046, 2110, 1934, 2303, 2514, 1994, 2094, 2744, 3951, 2944,
            2012, 2150, 1846, 2326, 3444, 5615, 4286, 3020, 2892, 2391,
            2557, 2256, 2539, 2549, 3399, 3570, 3047, 2907, 2317, 2126,
            1725, 1744, 2680, 2947, 3019, 2803, 2652, 2233, 2459, 3161,
            3077, 2739, 2659, 2886
          ])
          setNewRegistrationsLabels(generateDateArray('2024-08-01', '2025-01-31'))
  
          setRegistrationEntryPointData([320259, 20391, 17366, 12805, 5205, 3532, 2303, 1576, 1576, 1335])
          setRegistrationEntryPointLabels(['', 'header', 'onboarding', 'paywall', 'follow', 'comment', 'ppto', 'menu', 'article_sub_page', 'sign in wall'])
          setConversionRate(0.41)
          setAverageDaysFirstVisitRegistration(8)

        }
        
        setIsLoading(false)
      }, 1500);
      
    }, [pageType])


    const Extra = ({query_obj}) => (
        <SectionRow>
          <Tooltip title="Open in Explore">
            <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}} href={`https://exodus-cube.scmp.com/app/?query=${query_obj}`}>
            <Button 
              type="secondary" 
              shape="round" 
              icon={<ExportOutlined />} 
            />
            </Link>
          </Tooltip>
        </SectionRow>
      );

    return (
    <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        {pageType === 'traffic' ? <Col span={24}>
          <Row>
              <Col span={12}>
              <StyledCard 
                  title='Conversion Rate'
                  style={{marginRight: 4, marginTop: 0}}
                  onMouseEnter={() => setShowConversionRate(true)}
                  onMouseLeave={() => setShowConversionRate(false)}
                  extra={showAvgStoriesRead ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreConversionRateQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderArticlePlatformUnion.isLoading ?  */
                      isLoading ?
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Skeleton.Node active></Skeleton.Node></div>
                    : 
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                      <div style={{fontSize: 48, fontWeight: 600}}>{conversionRate}%</div>
                      <div style={{fontSize: 16}}>(since first visit)</div>
                    </div>}
                </StyledCard>
                <StyledCard 
                  title='Registration Entry Point'
                  style={{marginRight: 4}}
                  onMouseEnter={() => setShowEntryPoint(true)}
                  onMouseLeave={() => setShowEntryPoint(false)}
                  extra={showEntryPoint ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreRegistrationEntryPointQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderRegistrationEntryPoint.isLoading ?  */
                      isLoading ?
                      <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <BarChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node></div> : 
                      <Bar 
                        options={{
                          scales: {
                            x: { display: false },
                            y: {
                              grid: {
                                display: false
                              }
                            }
                          },
                          indexAxis: 'y',
                          elements: {
                            bar: {
                              borderWidth: 2,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {display: false},
                            datalabels: {
                              display: true,
                              color: "black",
                              formatter: (value, ctx) => {return (value).toLocaleString('en-US')},
                              anchor: "center",
                              offset: 16,
                              align: "end",
                              font: {
                                size: 14
                              },
                            }
                          },
                        }} 
                        data={getRegistrationEntryPointChartData(registrationEntryPointData, registrationEntryPointLabels)}
                        type='bar'
                        height={300}
                      />}
                </StyledCard>
              </Col>
              <Col span={12}>
                <StyledCard 
                  title='New Registrations'
                  style={{marginLeft: 4, marginTop: 0}}
                  onMouseEnter={() => setShowNewRegistrations(true)}
                  onMouseLeave={() => setShowNewRegistrations(false)}
                  extra={showNewRegistrations ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreNewRegistrationsQuery(articleID, dateRange)))} /> : <div></div>}
                >
                
                    {
                      /* renderNewRegistrations.isLoading ?  */
                      isLoading ?
                      <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                              <AreaChartOutlined
                                                                style={{
                                                                  fontSize: 40,
                                                                  color: '#bfbfbf',
                                                                }}
                                                              />
                                                            </Skeleton.Node>
                      </div> : 
                      <Line
                        type="area"
                        data={getNewRegistrationsChartData(newRegistrationsData, newRegistrationsLabels)}
                        height={300}
                        options={{
                          ...commonOptions,
                          scales: {
                            ...commonOptions.scales,
                            y: {
                              stacked: false,
                              beginAtZero: true
                            },
                          },
                          plugins: {
                            legend: {display: false},
                            datalabels: {
                              display: false
                            },
                            ...commonOptions.plugins
                          },
                        }}
                        // onClick={getElementAtEvent}
                      />
                    }
                </StyledCard>
                <StyledCard 
                  title=''
                  style={{marginLeft: 4}}
                  onMouseEnter={() => setShowTimeOnPlatform(true)}
                  onMouseLeave={() => setShowTimeOnPlatform(false)}
                  extra={showTimeOnPlatform ? <Extra query_obj={encodeURIComponent(JSON.stringify(getExploreRegionQuery(articleID, dateRange)))} /> : <div></div>}
                >
                    {
                      /* renderArticlePlatformUnion.isLoading ?  */
                      isLoading ?
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Skeleton.Node active></Skeleton.Node></div>
                    : 
                    <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                      <div style={{fontSize: 48, fontWeight: 600}}>{averageDaysFirstVisitRegistration} days</div>
                      <div style={{fontSize: 16}}>before registration on average</div>
                    </div>}
                </StyledCard>
              </Col>
          </Row>
          </Col> : 
            <Col span={24} justify="center" align="middle">
                <Col style={{marginBottom: 32}}><img src={`${process.env.PUBLIC_URL}/coming_soon.svg`} alt="Coming Soon illustration" width='720'/></Col>
                <Col><Title level={3}>Coming soon...</Title> </Col>
            </Col>
         }
        </QueryContext>
  );
}